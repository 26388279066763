import React, { useEffect } from "react";
import clsx from "clsx";
import { Typography } from "../../../Typography";

import { CourseInfoContainer } from "./CourseInfoStyled";

export interface Props {
	name: string;
	description: string;
	truncateDescription?: boolean;
}

const CourseInfoWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const { name, description, truncateDescription } = props;
	return (
		<CourseInfoContainer {...props}>
			<Typography variant='title' size='18px' margin='0 0 21px 0' fontWeight='bold'>
				{name}
			</Typography>
			<div
				className={clsx("descriptionWrapper", {
					truncateDescription: truncateDescription,
				})}>
				<Typography variant='text'>
					<div dangerouslySetInnerHTML={{ __html: description }} />
				</Typography>
			</div>
		</CourseInfoContainer>
	);
};

export { CourseInfoWrapper as CourseInfo };
