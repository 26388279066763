import React, { useEffect, useState } from "react";
import { useUserContext } from "../../../context/user/AuthContext";
import { SubscriptionBannerContainer, BannerDestaque } from "./SubscriptionBannerStyled";

import api from "../../../services/api";

// Chamada pra ver as informações de assinatura
// api.get("contrato/dados-assinatura/").then((response) => console.log("INFO ASSINATURA =>", response));
export interface Props { }

const SubscriptionBannerWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [isMonthlySub, setIsMonthlySub] = useState(false);
	const { isPlus } = useUserContext();

	const checkRecurrencyPeriod = async () => {
		const response = await api.get("contrato/dados-assinatura/");
		//console.log("RESPONSE DADOS ASSINATURA ", response.data.recurrency_period_text);
		if (response && response.data.recurrency_period_text && response.data.recurrency_period_text.toLowerCase() === "mensal") {
			return setIsMonthlySub(true);
		}
		return;
	};

	useEffect(() => {
		checkRecurrencyPeriod();
	}, []);
	return (
		<SubscriptionBannerContainer {...props}>
			{/* {!isPlus() && (
				<BannerDestaque>
					<a
						target='_blank'
						title='Torne-se Aluno Plus por apenas R$ 1,00 no primeiro mês'
						href='https://pay.hotmart.com/G10312620V?off=i8qcgtow&checkoutMode=10&hideBillet=1&utm_source=portalCiaAutismo&utm_campaign=plataformaNova&utm_media=banner'>
						<img src={BannerAssinatura} alt='' className='desk' />
						<img src={BannerAssinaturaMobile} alt='' className='mob' />
					</a>
				</BannerDestaque>
			)}
			{isPlus() && isMonthlySub && (
				<BannerDestaque>
					<a
						target='_blank'
						title='Seja assinante anual e economize!'
						href='https://pay.hotmart.com/G10312620V?off=bkzb39d6&checkoutMode=10&hideBillet=1&utm_source=portalCiaAutismo&utm_campaign=plataformaNova&utm_media=banner'>
						<img src={BannerAssinaturaAnual} alt='' className='desk' />
						<img src={BannerAssinaturaAnualMobile} alt='' className='mob' />
					</a>
				</BannerDestaque>
			)} */}
		</SubscriptionBannerContainer>
	);
};

SubscriptionBannerWrapper.defaultProps = {};

export { SubscriptionBannerWrapper as SubscriptionBanner };
