import React, { useCallback, useEffect, useState } from "react";
import { ClassFileUploaderContainer, FileItemsList } from "./ClassFileUploaderStyled";
import { useDropzone } from "react-dropzone";
import { isMobile } from "../../isMobile";
import { MdFileUpload } from "react-icons/md";

import CourseService from "../../services/course.service";

import { IClassFile, IClassFileResponse } from "../../types/course";
import { Button, ClassFileItem } from "../elements";

import loadingGif from "../../assets/images/loading.gif";

export interface Props {
	classId: number;
	maxFiles: number;
	onUpload?: (file: IClassFile) => void;
}

const ClassFileUploaderWrapper: React.FC<Props> = ({ classId, maxFiles, onUpload, ...props }: Props) => {
	const [sentFilesList, setSentFilesList] = useState<IClassFile[]>([]);
	const [classFilesResponse, setClassFilesResponse] = useState<IClassFileResponse>({} as IClassFileResponse);
	const [filesToSend, setFilesToSend] = useState<any[]>([]);
	const [uploading, setUploading] = useState(false);
	const [remainingUploads, setRemainingUploads] = useState(maxFiles);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: ".pdf, .ppt, .pptx, .doc, .docx, .txt, .xls, .xlsx, .jpg, .png, .jpeg",
		maxSize: 5242880,
		maxFiles: maxFiles,
	});

	const sentFiles = sentFilesList.map((file: IClassFile) => <ClassFileItem type='classFile' key={file.id} file={file} />);

	const files = filesToSend.map((file, index) => (
		//@ts-ignore
		<ClassFileItem file={file} key={file.path} removeAction={() => removeFileFromUploadList(index)} type='file' />
	));

	const removeFileFromUploadList = (index: number) => {
		let fts = [...filesToSend];
		fts.splice(index, 1);
		setFilesToSend([...fts]);
	};

	const sendFilesToServer = useCallback(async () => {
		setUploading(true);
		const courseService = new CourseService();
		const formData = new FormData();

		let sent = [...sentFilesList];

		for (let index in filesToSend) {
			formData.append(`arquivo`, filesToSend[index]);

			const response = await courseService.sendFilesToClass(classId, formData);

			// console.log("RESPONSE FROM UPLOAD COMPONENT ==> ", response);
			sent.push(response);

			if (onUpload) {
				onUpload(response);
			}

			formData.delete(`arquivo`);
		}

		//console.log("SENT =>", sent);

		setSentFilesList(sent);
		setFilesToSend([]);

		setUploading(false);
	}, [sentFilesList, acceptedFiles, filesToSend]);

	const getSentClassFiles = useCallback(async () => {
		const courseService = new CourseService();
		const response = await courseService.getClassFiles(classId);

		if (response[0]) {
			const sentFiles = response[0].arquivos;
			setClassFilesResponse(response[0]);
			setSentFilesList(sentFiles);

			setRemainingUploads(maxFiles - sentFiles.length);
		}
	}, []);

	useEffect(() => {
		setFilesToSend([...filesToSend, ...acceptedFiles]);
		//console.log(acceptedFiles);
	}, [acceptedFiles]);

	useEffect(() => {
		// setRemainingUploads(maxFiles);
		getSentClassFiles();
	}, []);

	return (
		<ClassFileUploaderContainer {...props}>
			{sentFiles.length > 0 && classFilesResponse.nota && (
				<p>
					<b>Nota: </b>
					{classFilesResponse.nota}
				</p>
			)}
			{sentFiles.length > 0 && classFilesResponse.observacao && (
				<p>
					<b>Observações Gerais: </b>
					<br />
					{classFilesResponse.observacao}
				</p>
			)}
			{sentFiles.length > 0 && (
				<>
					<section className='container'>
						<aside>
							<h4>Arquivos Enviados:</h4>
							{sentFilesList.length >= maxFiles && <small>Você já enviou os {maxFiles} arquivos possíveis para esta aula.</small>}
							<FileItemsList>{sentFiles}</FileItemsList>
						</aside>
					</section>
				</>
			)}
			{sentFilesList.length < maxFiles && (
				<section className='container dropzoneContainer'>
					<>
						{uploading && (
							<div className='uploading'>
								<img src={loadingGif} />
								<p>Carregando arquivos...</p>
							</div>
						)}
						<h4>Enviar Arquivos:</h4>
						<br />
						{/* {maxFiles} */}
						{remainingUploads < maxFiles && (
							<small>
								Você ainda pode enviar {remainingUploads} arquivo{remainingUploads === 1 ? "" : "s"}
							</small>
						)}
						<br />
						<br />
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<MdFileUpload size={45} />
							<p>
								Clique aqui{isMobile.any() ? "" : ", ou arraste para cá,"} para enviar os arquivos para a aula.
								<br />
								Você pode enviar no máximo {maxFiles} arquivos e com tamanho máximo de 5MB cada.
								<br />
								Tipos de arquivos aceitos: .pdf, .ppt, .pptx, .doc, .docx, .txt, .xls, .xlsx, .jpg, .png, .jpeg
							</p>
						</div>
						{filesToSend.length > 0 && (
							<>
								<aside>
									<h5>Arquivos na fila para enviar:</h5>
									<FileItemsList>{files}</FileItemsList>
								</aside>
							</>
						)}
						{filesToSend.length > 0 && !uploading && (
							<Button onClick={sendFilesToServer} variant='contained' color='primary'>
								Enviar Arquivos
							</Button>
						)}
					</>
				</section>
			)}
		</ClassFileUploaderContainer>
	);
};

ClassFileUploaderWrapper.defaultProps = {};

export { ClassFileUploaderWrapper as ClassFileUploader };
