import React from "react";
import Routes from "./routes";
import { GlobalStyles } from "./styles/globalStyled";
import muiTheme from "./styles/muiTheme";
import Theme from "./styles/theme";

// import { blueTheme } from "@joinlearn/package";
//import { store, persistor } from "./store";
//import { PersistGate } from "redux-persist/integration/react";
//import { Provider } from "react-redux";

import TagManager from "react-gtm-module";
import { ThemeProvider } from "styled-components";
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import { AuthProvider } from "./context/user/AuthContext";
import { LanguageProvider } from "./context/language/languageContext";
//

const tagManagerArgs = {
	gtmId: "",
};

TagManager.initialize(tagManagerArgs);

export default function App() {
	return (
		///<Provider store={store}>
		//<PersistGate loading={null} persistor={persistor}>
		<StylesProvider injectFirst>
			<MuiThemeProvider theme={muiTheme}>
				<ThemeProvider theme={Theme}>
					<GlobalStyles />
					<LanguageProvider>
						<AuthProvider>
							<Routes />
						</AuthProvider>
					</LanguageProvider>
				</ThemeProvider>
			</MuiThemeProvider>
		</StylesProvider>
		//</PersistGate>
		//</Provider>
	);
}
