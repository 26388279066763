import styled from 'styled-components';
import { Props } from './index';

const PageTitleContainer = styled.div<Props>`
  display: flex;
  height: 70px;
  background: #FFF;
  justify-content: center;
  flex-direction: column;
`;
export { PageTitleContainer };
