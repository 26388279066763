import styled from "styled-components";

const width = "30px";
const height = width;

const LanguageSelectorContainer = styled.div`
	margin: 0 2rem 0 auto;
	ul {
		display: flex;
		list-style: none;
		gap: 0.7rem;
		li {
			border-radius: 500px;
			width: ${width};
			height: ${height};
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.active {
			button {
				border: 2px solid ${(props) => props.theme.color.primary};
				border-radius: 500px;
			}
		}
	}
	button {
		cursor: pointer;
		background: none;
	}
	img {
		width: ${width};
		display: block;
	}
`;
export { LanguageSelectorContainer };
