import BaseService from "./base.service";
import api from "./api";
export default class MultimidiasVideosService extends BaseService {
	constructor(destaque?: boolean) {
		if (destaque) {
			super("multimidia/videos/destaque");
		} else {
			super("multimidia/videos/categorias");
		}
	}

	async mark_as_seen(id: number) {
		try {
			const response = await api.get(`multimidia/videos/${id}/assistir/`);
			console.log("Vídeo Assistido => ", response.data);
			return response.data;
		} catch (err) {
			console.error(`erro ao marcar vídeo como visto :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return { success: false };
		}
	}

	async get_first_content() {
		try {
			const response = await api({
				method: "GET",
				url: "multimidia/videos/primeiro-conteudo/",
			});
			// console.log("PRIMEIRO CONTEUDO ==>", response.data);

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao Receber Primeiro Conteúdo ${this.resource} base service:`, err);
			// console.error(`[BS] Erro ao Receber Primeiro Conteúdo ${this.resource} base service:`, err.response);
			return {
				error: true,
				status: err,
			};
		}
	}

	async complete_first_content() {
		try {
			const response = await api({
				method: "POST",
				url: "multimidia/videos/primeiro-conteudo/concluir/",
			});
			// console.log("CONCLUIR PRIMEIRO CONTEUDO ==>", response.data);

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao Receber Primeiro Conteúdo ${this.resource} base service:`, err);
			// console.error(`[BS] Erro ao Receber Primeiro Conteúdo ${this.resource} base service:`, err.response);
			return {
				error: true,
				status: err,
			};
		}
	}
}
