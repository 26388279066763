import { ILanguageStringList } from "../types/language";

export const ptbr: ILanguageStringList = {
	language: "Italianno",
	appName: "Agência B42",
	appDescription: "Plataforma oficial dos cursos da Agência B42",
	subtitle: "Plataforma oficial dos cursos da Agência B42",
	featureSliderTitle: "Destaques",
	categoryText: "Categoria",
	categoriesText: "Categorias",
	tagsText: "Tags",
	recommendedArticles: "Leia Também",
	exclusiveSectionText: "Esta seção é exclusiva para Alunos Plus, como você!",
	wrongCredentials: "Credenciais de acesso erradas",
	loadMore: {
		articles: "Ver mais artigos",
		news: "Ver mais notícias",
		downloads: "Ver mais downloads",
		comments: "Ver mais comentários",
	},
	meta: {
		in: "em",
		likeArticle: "Curtir artigo",
		likedArticle: "Você curtiu este artigo",
		likeNews: "Curtir",
		likedNews: "Você curtiu",
		source: "Fonte",
		credits: "Créditos",
	},
	comments: {
		title: "Comentários",
		new: "Novo Comentário",
		description: "Descrição",
		createModalTitle: "Novo Comentário",
		editModalTitle: "Editar Comentário",
		saveBtn: "Salvar",
		cancelBtn: "Cancelar",
	},
	titles: {
		articles: "Artigos",
		singleArticle: "Exibindo Artigo",
		multimedia: "Videos",
		news: "Notícias",
		home: "Início",
		courses: "Cursos",
		downloads: "Downloads",
	},
	header: {
		logout: "Sair",
	},
	footer: {
		copyrightText: `${new Date().getFullYear()} - Agencia B42®`,
		privacyPolicy: {
			text: "Política de Privacidade",
			link: "",
		},
		terms: {
			text: "Termos de uso",
			link: "",
		},
	},
	login: {
		user: "Usuário",
		password: "Senha",
		forgot: "Esqueci minha senha",
		loginBtn: "Entrar",
		rightText: "",
	},
	passwordRecovery: {
		rightText: "Recuperação de senha",
		inputPlaceholder: "Seu email",
		backLink: "Voltar ao login",
		sendButton: "Enviar",
	},
	home: {
		mainCourses: "Cursos Principais",
		thisWeek: "Destaques da Semana",
		featuredNews: "Notícia em Destaque",
		featuredArticle: "Artigo em Destaque",
		multimedia: "Videos",
		otherCourses: "Outros Cursos",
	},
	news: {
		latestNews: "Últimas notícias",
		newResponse: "Comentar",
	},
	multimedia: {
		playlistContent: "Conteúdo",
		released: "Liiberado",
		episode: "Episódio",
	},
	courses: {
		seeMoreClasses: "Ver mais aulas",
		createdAt: "Criado em",
		card: {
			status: {
				notStarted: "Não iniciado",
				finished: "Concluído",
				inProgress: "Em andamento",
			},
			availableClasses: "Aulas disponíveis",
			hasCertificate: "Com Certificado",
		},
		course: {
			quizAlertMessage: "Conclua os questionários anteriores para acessar essa aula",
			quizAnswered: "Respondido",
			unread: "Não lidos",
			newComments: "Novos comentários",
			courseContent: "Conteúdo do Curso",
			released: "Liberada",
			lessonNum: "Aula",
			optional: "Aula Opcional",
			lessonDoneLabel: "Concluída",
			markAsDoneBtn: "Marcar como concluída",
			downloadsCard: "downloads nesta aula",
			downloadsLesson: "Downloads",
			sendfiles: "Click here or drag your files here to upload them. Accepted files: .pdf, .ppt, .pptx, .doc, .docx, .txt, .xls, .xlsx, .jpg, .png, .jpe",
			sendFiles2: "The number of files you can send is:",
			noModules: "Nenhum módulo encontrado",
			readMore: "Leia mais",
			readLess: "Recolher",
			comments: {
				tittle: "Comentários",
				updateBtn: "Atualizar comentários",
				loading: "Carregando",
				loadMore: "Carregar mais comentários",
			},
			buttons: {
				nextLesson: "Próxima aula",
				prevLesson: "Aula anterior",
				emitCertificate: "Emitir certificado",
				emitBadge: "Emitir selo",

				issuing: "Emitindo",
			},
			quiz: {
				description: "Descrição do Questionário",
				prevResults: "Resultsados anteriores:",
				answersText: "O gabarito será exibido após você esgotar todas as suas chances de responder ao questionário.",
				chances: "Tentativas:",
				text: "Questionário",
				quizTry: "Tentativa",
				grade: "Nota",
			},
		},
	},
	profile: {
		achievements: "Conquistas",
		cancelBtn: "Cancelar",
		saveBtn: "Salvar",
		addressMap: "Endereço e Mapa",
		editMap: "Editar",
		claimed: "Emitido",
		certificates: "Meus Certificados",
		badges: "Meus Selos",
		termsAccepted: "Meus contratos aceitos",
		buttons: {
			edit: "Editar Perfil",
			logout: "Sair",
			changePassword: "Trocar senha",
		},
		changePasswordModal: {
			title: "Trocar senha",
			oldPassword: "Senha atual",
			newPassword: "Nova senha",
			confirmPassword: "Confirmar nova senha",
		},
		updateAvatarModal: {
			title: "Atualizar foto",
			fileButton: "Escolher um arquivo",
		},
		editProfileModal: {
			title: "Editar Perfil",
			name: "Nome",
			email: "Email",
			occupation: "Profissão",
			personalText: "Sobre você",
			phone: "Celular",
			address: {
				street: "Logradouro",
				number: "Número",
				extraInfo: "Complemento",
				neighborhood: "Bairro",
				zip: "CEP",
				city: "Cidade",
				state: "Estado/UF",
			},
			notifications: {
				title: "Notificações",
				articles: "Novos Artigos",
				downloads: "Novos Arquivos para Download",
				multimedia: "Nova Multimídia",
				courses: "Atualizações de Curso",
				comments: "Novas respostas aos seus comentários",
				news: "Novas Notícias",
			},
		},
		availabilityToggle: {
			title: "Disponibilidade",
			public: "Público",
			private: "Privado",
		},
	},
};
