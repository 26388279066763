import api from "./api";
import BaseService from "./base.service";
import { Course, QuizAnswer } from "../types/course";

export default class CourseService extends BaseService {
	constructor() {
		super("cursos");
	}

	parseReleaseDate(dateString: string) {
		return dateString.split(" ")[0];
	}

	courseListFormatData(course: any) {
		return {
			name: course.nome,
			slug: course.slug,
			imageUrl: course.imagem,
			teachers: course.professores,
			description: course.descricao,
			certificate: course.certificado,
			status: course.status,
			releasedLessons: course.aulas_liberadas_count,
			discountPercent: course.percent_desconto,
			bestSelling: course.selo_mais_vendido,
			progress: course.progresso,
			recommended: course.recomendado,
			hasAccess: course.tem_acesso,
			landingPageLink: course.link_vendas,
			isOpenForSale: course.inscricoes_abertas,
		};
	}

	formatListData(courses: any) {
		const courses_formated = courses.map((course: any) => this.courseListFormatData(course));

		return courses_formated;
	}

	formatMaterials(materials: any) {
		return materials.map((material: any) => ({
			id: material.id,
			name: material.titulo,
			url: material.arquivo,
		}));
	}

	formatLesson(lesson: any) {
		if (!lesson) return {};
		return {
			id: lesson.id,
			name: lesson.nome,
			completed: lesson.aula_concluida ? lesson.aula_concluida : false,
			releaseDate: this.parseReleaseDate(lesson.data_liberacao_ou_publicacao),
			materialsCount: lesson.materiais.length,
			videoUrl: lesson.video,
			description: lesson.descricao,
			materials: this.formatMaterials(lesson.materiais),
			isReleased: lesson.liberada,
			nonReadComments: lesson.comentarios_nao_lidos,
			timestamp: lesson.ultima_visualizacao,
			isOptional: lesson.optativa,
			blockedByQuiz: lesson.bloqueada_por_questionario,
			acceptsUpload: lesson.aceita_documentos,
			blockedByUpload: lesson.requer_correcao_documentos,
			maxUploadableFiles: lesson.num_max_documentos ? lesson.num_max_documentos : 0,
			timeline: lesson.timeline,

			// debates?: DebateItem[]
		};
	}

	formatLessons(lessons: any, courseName: any, moduleName: any) {
		return lessons.map((lesson: any) => ({
			...this.formatLesson(lesson),
			courseName,
			moduleName,
		}));
	}

	formatModule(module: any, courseName: any) {
		let moduleLessonsArray: any;
		if (module.aulas.length > 0) {
			moduleLessonsArray = this.formatLessons(module.aulas, courseName, module.nome);
		} else {
			moduleLessonsArray = [];
		}
		return {
			id: module.id,
			name: module.nome,
			releaseDate: moduleLessonsArray.length > 0 ? moduleLessonsArray[0].releaseDate : "",
			moduleClasses: moduleLessonsArray,
			classesCount: module.aulas.length > 0 ? module.aulas.length : 0,
			completedClasses: 10,
			progress: 57,
			released: true,
			students: [],
			studentsCount: 0,
			quizID: module.questionario,
			quizReleaseDate: module.data_liberacao_questionario,
			isQuizReleased: module.questionario_liberado,
			hasComments: module.existe_comentarios_nao_lidos,
			cor: module.cor,
			hasSeparador: module.has_separador,
			textoSeparador: module.texto_separador,
		};
	}

	formatModules(modules: any, courseName: any) {
		return modules.map((module: any) => this.formatModule(module, courseName));
	}

	formatDetailData(course: any) {
		const formated_course: Course = {
			id: course.id,
			name: course.nome,
			slug: course.slug,
			modules: [],
			imageUrl: course.imagem,
			teachers: course.professor,
			description: course.descricao,
			status: "Faltando essa info",
			releaseDate: "",
			students: [],
			studentsCount: 0,
			progress: 68,
			allowComments: course.comentarios_ativos,
			isFinished: course.is_finished,
			isComplete: course.curso_completo,
			hasCertificate: course.certificado,
			isCertificateAvailable: course.certificate_available,
			certificateUrl: course.certificate_url,
			sealUrl: course.selo_url,
			isSealAvailable: course.selo_available,

			hasAccess: course.tem_acesso,
			landingPageLink: course.link_vendas,
			isOpenForSale: course.inscricoes_abertas,
		};
		return formated_course;
	}

	async get_last_seen(slug: string) {
		try {
			const response = await api.get(`${this.resource}/status-aulas/${slug}/`);
			//console.log("ÚLTIMA AULA ASSISTIDA => ", response.data[0].aula);
			if (!response.data[0]) return undefined;
			return response.data[0].aula;
		} catch (err) {
			console.error(`erro ao listar ${this.resource} :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return { success: false };
		}
	}

	async mark_as_last_seen(lessonId: number) {
		try {
			const response = await api.get(`${this.resource}/aula/${lessonId}/assistir/`);
			//console.log("COMENTÁRIOS MARCADOS COMO LIDOS => ", response);
			return { success: true };
		} catch (err) {
			console.error(`erro ao listar ${this.resource} :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return { success: false };
		}
	}

	async mark_lesson_as_complete(lessonId: number) {
		try {
			const response = await api.get(`${this.resource}/aula/${lessonId}/concluir/`);
			//console.log("SET LESSON =>", response.data);
			return { success: true };
		} catch (err) {
			console.log(`erro ao listar ${this.resource} :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return { success: false };
		}
	}

	async get_modules_by_slug(courseName: string, slug: string) {
		try {
			const response = await api.get(`${this.resource}/modulos/${slug}/`);
			// console.log("GET MODULES BY Slug DATA", response.data);
			return this.formatModules(response.data, courseName);
		} catch (err) {
			console.log(`erro ao listar ${this.resource} :`, err);
			return {
				id: "",
				name: "",
				slug: "",
				modules: [],
				imageUrl: "",
				teachers: [],
				description: "",
				studentsCount: 0,
				releaseDate: "",
				students: [],
				status: "",
				progress: 68,
			};
		}
	}

	async get_quiz_by_module_id(moduleId: number) {
		try {
			const response = await api.get(`/questionarios/${moduleId}/`);
			// console.log("GET QUIZ BY MODULE ID => ", response.data);
			return response.data;
		} catch (error) {
			console.error("Erro ao pegar questionário", error);
		}
	}

	async get_certificate(certificateUrl: string) {
		try {
			const response = await api.get(certificateUrl, { responseType: "arraybuffer" });
			// console.log("Certificate Response => ", response.data);
			return response.data;
		} catch (error) {
			console.error("Erro ao pegar questionário", error);
		}
	}

	async answer_quiz(answers: QuizAnswer[], moduleId: number) {
		try {
			const response = await api.post(`/questionarios/${moduleId}/responder/`, answers);
			return response.data;
		} catch (error) {
			console.error("Erro ao responder questionário", error);
		}
	}

	async boletim() {
		try {
			const response = await api.get(`/cursos/boletim/`);
			return response.data;
		} catch (error) {
			console.error("Erro ao processar o boletim", error);
		}
	}

	async sendFilesToClass(aulaId: number, data: any) {
		//console.log("Files FROM FORM DATA => ", data);

		const uri = `${this.resource}/aula/${aulaId}/enviar-arquivo/`;
		try {
			const response = await api({
				method: "post",
				url: uri,
				data: data,
				headers: { "Content-Type": undefined },
			});

			return response.data;
		} catch (err) {
			console.log(`[BS] Erro ao enviar arquivos para aula ${uri} :`, err);
			//console.log(`[BS] Erro ao enviar arquivos para aula ${uri} :`, err.response);

			return {
				error: true,
			};
		}
	}

	async getClassFiles(aulaId: number) {
		try {
			const response = await api.get(`${this.resource}/aula/${aulaId}/arquivos/`);
			// console.log("ARQUIVOS DA AULA => ", response.data);
			return response.data;
		} catch (err) {
			console.log(`erro ao listar ${this.resource} :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return { success: false };
		}
	}
}
