import React from 'react';
import { useLanguageContext } from '../../context/language/languageContext';
import { TLanguage } from '../../language';
import { LanguageSelectorContainer } from './LanguageSelectorStyled'

import ptbrFlag from '../../assets/images/flags/brazil.png'
import enusFlag from '../../assets/images/flags/usa.png'
// import esFlag from '../../assets/images/flags/spain.png'
// import itFlag from '../../assets/images/flags/italia.png'
// import coFlag from '../../assets/images/flags/colombia.png'

export interface Props {
  languages: TLanguage[]
}

const LanguageSelectorWrapper: React.FC<Props> = ({
  languages,
  ...props
}: Props) => {
  const { pickLanguage, currentLanguage } = useLanguageContext();
  return (
    <LanguageSelectorContainer {...props}>
      <ul>
        {languages.includes('ptbr') &&
          <li className={currentLanguage === 'ptbr' ? 'active' : ''}>
            <button onClick={() => pickLanguage('ptbr')} title='Português (BR)'>
              <img src={ptbrFlag} alt="Português (BR)" />
            </button>
          </li>
        }
        {languages.includes('enus') &&
          <li className={currentLanguage === 'enus' ? 'active' : ''}>
            <button onClick={() => pickLanguage('enus')} title='English (US)'>
              <img src={enusFlag} alt="English (US)" />
            </button>
          </li>
        }
      </ul>
    </LanguageSelectorContainer>
  );
};

LanguageSelectorWrapper.defaultProps = {

}

export { LanguageSelectorWrapper as LanguageSelector };