import React from 'react';

import { IconWrapper } from '../IconWrapper';


export interface Props extends React.HTMLAttributes<HTMLElement> {
  rounded?: boolean;
  filled?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: string;
  tooltipText?: string;
  primaryColor?: boolean;
}


const CompletedCourseIconWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <IconWrapper
    {...props}
    tooltipText={props.tooltipText}
    backgroundColor={props.backgroundColor}
    size={props.size}
    rounded={props.rounded}
    primaryColor={props.primaryColor}
    icon={(
      <svg width="19" height="24" viewBox="0 0 19 24" xmlns="http://www.w3.org/2000/svg">
        <path fill={props.color} d="M17.7297 1.60894L17.7297 1.11051e-07L19 0L19 24L17.7297 24L17.7297 13.7296L-7.07068e-07 13.7296L-1.76669e-06 1.60894L17.7297 1.60894ZM1.08108 9.33184L2.16216 9.33184L4.43243 9.33184L4.43243 11.5844L4.43243 12.657L7.78378 12.657L7.78378 11.5844L7.78378 9.33184L11.1351 9.33184L11.1351 11.5844L11.1351 12.657L14.4865 12.657L14.4865 11.5844L14.4865 9.33184L16.7568 9.33184L17.7297 9.33184L17.7297 6.0067L16.7568 6.0067L14.4865 6.0067L14.4865 3.75419L14.4865 2.68156L11.1351 2.68156L11.1351 3.75419L11.1351 6.0067L7.78378 6.0067L7.78378 3.75419L7.78378 2.68157L4.43243 2.68157L4.43243 3.75419L4.43243 6.00671L2.16216 6.00671L1.08108 6.00671L1.08108 9.33184Z" />
      </svg>

    )}
  />
);

CompletedCourseIconWrapper.defaultProps = {
  rounded: true,
  size: '26px',
  filled: false,
  primaryColor: true,
};

export { CompletedCourseIconWrapper as CompletedCourseIcon };
