import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { PageContainer, GreyContainer, InnerContentContainer } from "../../components";
import { SquareCardLink, Button, CourseCardLoading } from "../../components/elements";
import DownloadsService from "../../services/downloads.service";
import DownloadsCategoriesService from "../../services/downloadsCategories.service";

import { DownloadsContainer, ViewMoreWrapper } from "./DownloadsStyled";
import { Tag } from "../../components/elements/SquareCard/SquareCardStyled";
import { CategoriesContainer, FilterTitle } from "../Articles/ArticlesStyled";
import { useParams } from "react-router-dom";
// import { IoIosClose } from "react-icons/io";

import loadingGif from "../../assets/images/loading.gif";
import { HomePageFeatureSlider } from "../../components/features/HomePageFeatureSlider";
import { useLanguageContext } from "../../context/language/languageContext";

export interface Props {
	background?: string;
}

interface IDownloadCategory {
	id: number;
	nome: string;
	descricao?: string;
	slug: string;
}

interface IDownload {
	arquivo: string;
	categoria: IDownloadCategory;
	cropping?: string;
	data_publicacao: string;
	id: number;
	imagem: string | null;
	texto: string;
	titulo: string;
}

const Downloads: React.FC<Props> = ({ ...props }) => {
	const { getLanguageStrings } = useLanguageContext();
	const [lastPage, setLastPage] = useState(false);
	const [downloadsNextPage, setDownloadsNextPage] = useState(1);
	const [downloads, setDownloads] = useState<IDownload[]>([]);
	const [downloadsCount, setDownloadsCount] = useState(0);
	const [viewMoreDisabled, setViewMoreDisabled] = useState(false);
	const [loadingDownloads, setLoadingDownloads] = useState(true);
	const [downloadCategories, setDownloadCategories] = useState<IDownloadCategory[]>([]);

	const { category } = useParams<{ category: string }>();

	// const history = useHistory();

	const getDownloads = useCallback(async () => {
		const downloadsService = new DownloadsService();
		const response = await downloadsService.list();

		if (response.next) {
			setDownloadsNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
			setLastPage(false);
		} else {
			setViewMoreDisabled(true);
			setLastPage(true);
		}

		setDownloads(response.results);
		setDownloadsCount(response.count);
		setLoadingDownloads(false);
	}, []);

	const loadMoreDownloads = useCallback(async () => {
		setViewMoreDisabled(true);

		const downloadsService = new DownloadsService(downloadsNextPage);
		const response = await downloadsService.list();

		setDownloads([...downloads, ...response.results]);

		if (response.next) {
			setDownloadsNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
		} else {
			setLastPage(true);
			setViewMoreDisabled(true);
		}
	}, [downloads, downloadsNextPage]);

	const trucateString = useCallback((txt: string | undefined, charCount: number) => {
		if (txt && txt.length > charCount) {
			return `${txt.substring(0, charCount)}[...]`;
		}
		return txt;
	}, []);

	const getArticlesByCategory = useCallback(async (category) => {
		const articlesService = new DownloadsService();
		const response = await articlesService.get_by_category_slug(category);

		if (response.next) {
			setDownloadsNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
			setLastPage(false);
		} else {
			setViewMoreDisabled(true);
			setLastPage(true);
		}
		setDownloads(response.results);
		setDownloadsCount(response.count);
		setLoadingDownloads(false);
	}, []);

	const getCategories = useCallback(async () => {
		const downloadsCategoriesService = new DownloadsCategoriesService();
		const response = await downloadsCategoriesService.list();
		// console.log("DOWNLOADS CATEGORIES", response);
		setDownloadCategories(response);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (category) {
			getArticlesByCategory(category);
		} else {
			getDownloads();
		}
		getCategories();
		// eslint-disable-next-line
	}, [category]);

	return (
		<PageContainer pageTitle={getLanguageStrings().titles.downloads} showSearch={false} showBreadcrumb pageTitleHighlightText={downloadsCount} plusExclusive>
			<FilterTitle>{getLanguageStrings().categoriesText}:</FilterTitle>
			<CategoriesContainer>
				{downloadCategories.map((cat, index) => (
					<React.Fragment key={index}>
						{cat.slug === category ? (
							<Tag to={`/materiais`} title='Remover Filtro' className='active'>
								{cat.nome}
							</Tag>
						) : (
							<Tag to={`/materiais/categoria/${cat.slug}`} title={cat.descricao}>
								{cat.nome}
							</Tag>
						)}
					</React.Fragment>
				))}
			</CategoriesContainer>
			<GreyContainer>
				<InnerContentContainer>
					<HomePageFeatureSlider location="MATERIAIS" />
					<DownloadsContainer {...props}>
						<Box
							width='100%'
							display='grid'
							gridTemplateColumns={{
								xs: "100%",
								sm: "100%",
								md: "24% 24% 24% 24%",
								// xl: '19% 19% 19% 19% 19%',
							}}
							gridRowGap='15px'
							gridColumnGap='1%'>
							{loadingDownloads ? (
								<>
									<CourseCardLoading /> <CourseCardLoading />
									<CourseCardLoading /> <CourseCardLoading />
								</>
							) : (
								<>
									{downloads.map((download: IDownload) => (
										<SquareCardLink
											key={download.id}
											downloadID={download.id}
											variant='download'
											href={download.arquivo}
											imageurl={download.imagem || "https://ajuda.nossomundoazul.com.br/other-assets/download-fallback.jpg"}
											title={download.titulo}
											description={download.texto}
											excerpt={trucateString(download.texto, 100)}
											meta={download.data_publicacao}
											category={download.categoria}
											isModal={download.texto ? true : false}></SquareCardLink>
									))}
								</>
							)}
						</Box>
						{!loadingDownloads && (
							<ViewMoreWrapper>
								<Button
									variant='contained'
									color='primary'
									disabled={viewMoreDisabled}
									onClick={() => {
										loadMoreDownloads();
									}}>
									{getLanguageStrings().loadMore.downloads}
								</Button>
								{viewMoreDisabled && !lastPage ? <img className='loadingGif' src={loadingGif} alt='loading' /> : ""}
							</ViewMoreWrapper>
						)}
					</DownloadsContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Downloads };
