import styled from "styled-components";
import { Props } from "./index";
import media from "../../styles/media";

interface LikeButtonProps {
	isLiked: boolean;
}

const ArticleDetailContainer = styled.article<Props>`
	margin-bottom: 30px;
`;

const ArticleHeaderSection = styled.header<Props>`
	padding: 0 0 25px;
	display: flex;
`;
const TitleSection = styled.div<Props>`
	flex: 1;
`;

const ButtonSection = styled.div<Props>`
	//width: 50px;
`;
const LikeButton = styled.button<LikeButtonProps>`
	//width: 140px;
	background: none;
	cursor: pointer;
	display: flex;
	border-radius: 6px;
	border: 1px solid ${(props) => (props.isLiked ? props.theme.color.primary : "#e5e5e5")};
	padding: 15px;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0);
	transition: 0.3s ease;

	outline: none;
	${media.lessThan("medium")`
		padding: 10px 10px 12px 10px;
	`};
	span {
		white-space: nowrap;
		display: block;
		padding: 4px 0 0;
		color: ${(props) => (props.isLiked ? props.theme.color.primary : "#5e5e5e")};
	}
	svg {
		margin: 0px 8px;
		color: ${(props) => (props.isLiked ? props.theme.color.primary : "#5e5e5e")};
	}
	&:hover {
		border: 1px solid ${(props) => props.theme.color.primary};
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		svg {
			color: ${(props) => props.theme.color.primary};
		}
		span {
			color: ${(props) => props.theme.color.primary};
		}
	}
`;
const ArticleTitle = styled.h1<Props>`
	font-size: 1.6rem;
	font-weight: 700;
	margin-bottom: 4px;
	color: ${(props) => props.theme.color.primary};
`;
const ArticleSubTitle = styled.h2<Props>`
	font-size: 1.2rem;
	font-style: italic;
	font-weight: 200;
	opacity: 0.4;
	margin-bottom: 6px;
`;
const ArticleMeta = styled.small<Props>`
	opacity: 0.6;
`;

const ArticleFeaturePhoto = styled.div<Props>`
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	&:before {
		content: "";
		display: block;
		padding-top: 20%;
		width: 100%;
	}
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		z-index: 2;
		&.loadingGif {
			z-index: 1;
			object-fit: none;
			width: 90px;
			height: 90px;
		}
	}
`;

const ArticleLoader = styled.div<Props>`
	width: 100%;
	min-height: 450px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SuggestedArticlesContainer = styled.section<Props>`
	margin-top: 30px;
`;

const ArticleBody = styled.section<Props>`
	margin: 25px 0 80px;
	img {
		display: block;
		margin: 0 auto 25px;
		max-width: 100%;
		height: auto !important;
	}
	p,
	ol,
	ul {
		margin: 0 0 25px;
		line-height: 1.2em;
		opacity: 0.8;
	}
	ol,
	ul {
		list-style-position: inside;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 7px;
		color: ${(props) => props.theme.color.primary} !important;
		strong {
			color: ${(props) => props.theme.color.primary} !important;
		}
	}
	.iframeContainerArtigo {
		width: 60%;
		margin: 0 auto;
		position: relative;

		${media.lessThan("medium")`
			width: 100%;
		`};
		&:before {
			content: "";
			display: block;
			padding-top: 56.25%;
			width: 100%;
		}
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
`;

const LoadErrorMessage = styled.div<Props>`
	width: 100%;
	min-height: 350px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ArticleCredits = styled.div`
	width: 100%;
	margin-bottom: 24px;
`;

export {
	ArticleDetailContainer,
	ArticleHeaderSection,
	TitleSection,
	ArticleTitle,
	ArticleSubTitle,
	ArticleMeta,
	ButtonSection,
	ArticleFeaturePhoto,
	SuggestedArticlesContainer,
	ArticleBody,
	ArticleLoader,
	LoadErrorMessage,
	LikeButton,
	ArticleCredits,
};
