import { Box } from "@material-ui/core";
import React from "react";
import { Typography, PageContainer, InnerContentContainer, GreyContainer } from "../../components";
import ReactPlayer from "react-player";

import { SejaPlusContainer, VideoWrapper, CTAButton } from "./SejaPlusStyled";

export interface Props {
	background?: string;
}

const SejaPlus: React.FC<Props> = ({ children, background = "#FFF", ...props }) => {
	return (
		<PageContainer showBreadcrumb={false} pageTitle='Torne-se Aluno PLUS da Academia do Autismo!' showUserAvatar={false} showSearch={false}>
			<GreyContainer>
				<InnerContentContainer>
					<SejaPlusContainer background={background} {...props}>
						<Box display='flex' justifyContent='space-between' flexWrap='wrap'>
							<div className='videoColumn'>
								<VideoWrapper>
									<iframe
										src='https://www.youtube.com/embed/souxZ3nufMU?autoplay=0'
										title='YouTube video player'
										// @ts-ignore
										frameborder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowfullscreen></iframe>
								</VideoWrapper>

								<Typography variant='text' margin='20px 0' size='1.125rem' fontWeight='bold'>
									Quer fazer parte? Garanta agora acesso à nossa plataforma e conteúdos com um valor na primeira mensalidade:
								</Typography>
								<CTAButton href='https://pay.hotmart.com/G10312620V?off=i8qcgtow&checkoutMode=10&utm_source=portalCiaAutismo&utm_campaign=plataformaNova&utm_media=pg_seja_plus' target='_blank'>
									QUERO ME TORNAR ALUNO PLUS POR R$1,00*
								</CTAButton>
								<Typography variant='text' margin='0 0 20px' size='0.85rem'>
									*Depois de 30 dias, será cobrado o valor de R$ 39,90/mês
								</Typography>
							</div>
							<div className='textColumn'>
								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Olá, estamos muito felizes em ter você conosco, mas também queremos te lembrar que, quando o assunto é autismo, a busca por conhecimento e qualificação nunca terminam!
								</Typography>
								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Por isso, queremos te convidar para ser Aluno PLUS da maior plataforma de educação continuada em autismo do Brasil: o CIA Autismo.
								</Typography>

								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Como isso funciona? Ao se inscrever em nosso curso, você teve acesso ao CIA Autismo - Centro Integrado de Aprendizagem em Autismo, para poder assistir suas aulas e tirar suas dúvidas
									com nossa equipe de ensino, certo?
								</Typography>
								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Ao se tornar Aluno PLUS, você vai ingressar num mundo de conhecimento, artigos, materiais, tópicos, séries e cursos totalmente exclusivos de forma ILIMITADA, com atualizações
									semanais. Além disso, você também terá acesso a um 1 novo curso TODOS OS MESES.
								</Typography>

								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Dá uma olhada no que já temos por aqui para aproveitar:
								</Typography>
								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Já são mais de 180 artigos exclusivos liberados, e um novo toda semana.
								</Typography>
								<Typography variant='text' margin='0 0 20px' size='1rem'>
									São mais de 10 cursos já liberados na plataforma (Simpósio Nacional de Autismo 1, 2, 3 e 4, Semana da Inclusão Escolar no TEA, Imersão em Terapia Comportamental no TEA e muito mais)
								</Typography>
								<ul>
									<li>Um curso novo liberado todo mês com certificado</li>
									<li>Mais de 80 ferramentas práticas já disponíveis para download e uma nova toda semana</li>
									<li>Séries, entrevistas e documentários exclusivos para entreter e capacitar</li>
									<li>Acesso ao Sociautism, software exclusivo para aplicação e gerência da Terapia ABA</li>
									<li>Comunidade com tópicos atualizados, notícias, dicas e aulas ao vivo</li>
								</ul>

								<Typography variant='text' margin='0 0 20px' size='1rem'>
									Ah, e mais uma novidade: ao ser Aluno Plus, você garante condições especiais em todos os nossos principais cursos.
								</Typography>

								<div className='ctaHideDesk'>
									<Typography variant='text' margin='20px 0' size='1.125rem' fontWeight='bold'>
										Quer fazer parte? Garanta agora acesso à nossa plataforma e conteúdos com um valor na primeira mensalidade:
									</Typography>
									<CTAButton href='https://pay.hotmart.com/G10312620V?off=i8qcgtow&checkoutMode=10&utm_source=portalCiaAutismo&utm_campaign=plataformaNova&utm_media=pg_seja_plus' target='_blank'>
										QUERO ME TORNAR ALUNO PLUS POR R$1,00*
									</CTAButton>
									<Typography variant='text' margin='0 0 20px' size='0.85rem'>
										*Depois de 30 dias, será cobrado o valor de R$ 39,90/mês
									</Typography>
								</div>
							</div>
						</Box>
					</SejaPlusContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { SejaPlus };
