import { Material, Student, Module, ModuleClass, Course, CourseCardInfo } from "../types/course";

//import { debateData, debateData2 } from "./debateDataMock";

const materialsData = (): Material[] => [
	{ id: 1, url: "http://google.com", name: "Apostila do curso" },
	{ id: 2, url: "http://google.com", name: "Tutorial de acesso às aulas" },
	{ id: 3, url: "http://google.com", name: "Dicas sobre reforçadores" },
	// { id: 4, url: 'http://google.com', name: 'Tutorial de acesso às aulas' },
];

const studentsData = (): Student[] => [
	{
		id: 1,
		imageUrl: "https://meeting.academiadoautismo.com.br/images/fabioeq.jpg",
		name: "Fábio Coelho",
	},
	{
		id: 2,
		imageUrl: "https://gabrielbaptista.com.br/joinlearn-prototipo/static-assets/images/users/vinicius.png",
		name: "Vinícius Loureiro",
	},
	{
		id: 3,
		imageUrl: "https://meeting.academiadoautismo.com.br/images/mayaraeq.jpg",
		name: "Mayara Coelho",
	},
	{
		id: 4,
		imageUrl: "https://meeting.academiadoautismo.com.br/images/chaloeeq.jpg",
		name: "Chaloê Comim",
	},
];

const moduleClassData = ({ ...props }): ModuleClass => {
	const data = {
		id: 1,
		name: "Atividades com Vogais e Consoantes",
		nome: "Atividades com Vogais e Consoantes",
		releaseDate: "21 de mai 2020",
		data_liberacao_ou_publicacao: "21 de mai 2020",
		materialsCount: 3,
		videoUrl: "https://www.youtube.com/watch?v=i8BAsCfJcF4",
		description: "Descrição da aula",
		materials: materialsData(),
		courseName: "ABA na Ed. Infantil",
		moduleName: "Modulo 1",
		debates: [],
		completed: true,
		isReleased: true,
		isOptional: false,
		blockedByQuiz: false,
		timestamp: 0,

		acceptsUpload: false,
		blockedByUpload: false,
		timeline: [],

		maxUploadableFiles: 0,
	};
	return { ...data, ...props };
};

const moduleData = ({ ...props }: any): Module => {
	const data = {
		id: 1,
		name: "Modulo 1",
		releaseDate: "7 nov 2019",
		completedClasses: 3,
		classesCount: 5,
		progress: 70,
		studentsCount: 42140,
		released: true,
		debates: [],
		moduleClasses: [
			moduleClassData({}),
			moduleClassData({
				id: 2,
				materialsCount: 3,
				name: "6 mitos dos negócios de assinatura",
				releaseDate: "23 junho 2020",
				videoUrl: "https://www.youtube.com/watch?v=3XFFqQkJNtk",
			}),
			moduleClassData({
				id: 3,
				materialsCount: 5,
				name: "9 Barreiras que dificultam o desenvolvimento infantil",
				releaseDate: "26 junho 2020",
				videoUrl: "https://www.youtube.com/watch?v=mLjM43oUKgI",
			}),
		],
		students: studentsData(),
	};
	return { ...data, ...props };
};

const blockedClasses = () => [
	moduleClassData({ id: 11, name: "Aula Sobre negócios" }),
	moduleClassData({
		id: 10,
		name: "Aula Um",
		releaseDate: "23 junho 2020",
		videoUrl: "https://www.youtube.com/watch?v=507DVHrp79U",
	}),
	moduleClassData({
		id: 4,
		name: "Aula Dois",
		releaseDate: "23 junho 2020",
		videoUrl: "https://www.youtube.com/watch?v=507DVHrp79U",
	}),
	moduleClassData({
		id: 5,
		name: "Aula",
		releaseDate: "23 junho 2020",
		videoUrl: "https://www.youtube.com/watch?v=507DVHrp79U",
	}),
	moduleClassData({
		id: 6,
		name: "Aula Quatro",
		releaseDate: "23 junho 2020",
		videoUrl: "https://www.youtube.com/watch?v=507DVHrp79U",
	}),
	moduleClassData({
		id: 7,
		name: "Aula Cinco",
		releaseDate: "23 junho 2020",
		videoUrl: "https://www.youtube.com/watch?v=507DVHrp79U",
	}),
];

const courseData = ({ ...props }): Course => {
	const data = {
		id: 1,
		name: "Curso ABA na Educação Infantil",
		description: "Breve descrição do curso",
		releaseDate: "7 mai 2020",
		students: studentsData(),
		studentsCount: 45,
		hasComments: true,
		slug: "teste",
		certificateUrl: "https://googlce.com",
		sealUrl: "https://googlce.com",
		imageUrl: "https://lorempixel.com",
		teachers: "Fábio Coelho",
		hasCertificate: false,
		isFinished: true,
		isComplete: false,
		isCertificateAvailable: false,
		isSealAvailable: false,
		status: "em curso",
		progress: 90,
		hasAccess: true,
		landingPageLink: "",
		isOpenForSale: false,
		modules: [
			moduleData({}),
			moduleData({ id: 2, name: "Modulo 2", moduleClasses: blockedClasses() }),
			moduleData({
				id: 3,
				name: "Modulo 3",
				released: false,
				moduleClasses: blockedClasses(),
			}),
			moduleData({
				id: 4,
				name: "Modulo 4",
				released: false,
				moduleClasses: blockedClasses(),
			}),
		],
	};

	return { ...data, ...props };
};

const courseCardInfo = ({ ...props }): CourseCardInfo => {
	const data = {
		name: "Curso ABA na Educação Infantil",
		imageUrl: "https://abanaedinfantil.academiadoautismo.com.br/images/fb_graph.jpg",
		teachers: ["Nathália Belmonte"],
		description: "",
		certificate: true,
		status: "in_progress",
		releasedLessons: 30,
		hasAccess: true,
		landingPageLink: "",
		isOpenForSale: false,
	};
	return { ...data, ...props };
};

const courseCardsInfo = () => [
	courseCardInfo({
		name: "Habilidades Sociais para Adolescentes e Adultos com TEA",
		imageUrl: "https://adolescenteseadultos.academiadoautismo.com.br/images/fb_graph.jpg",
		teachers: ["Mariana Cervi"],
		description: "",
		certificate: true,
		status: "in_progress",
		releasedLessons: 30,
	}),
	courseCardInfo({
		name: "Alfabetização no TEA",
		status: "completed",
		imageUrl: "https://alfabetizacaonotea.academiadoautismo.com.br/images/fb_graph.jpg",
		recommended: true,
		description: "",
		teachers: ["Maria Bispo"],
	}),
	courseCardInfo({
		name: "Recursos Musicais para Prática Terapêutica de Pessoas com TEA",
		imageUrl: "https://recursosmusicais.academiadoautismo.com.br/images/fb_graph.jpg",
		teachers: ["Michele Senra"],
		releasedLessons: 10,
		discountPercent: 10,
		description: "",
		// bestSelling: true,
	}),
	courseCardInfo({
		name: "Curso em Terapia ABA no Autismo para Pais e aplicadores",
		teachers: ["Fábio Coelho"],
		imageUrl: "https://cursoaba.academiadoautismo.com.br/images/fb_graph.jpg",
		description: "",
	}),
];

export { materialsData, moduleData, studentsData, courseData, moduleClassData, courseCardsInfo, courseCardInfo };
