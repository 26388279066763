import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import { ViewClassHeader, MaterialsList, CourseInfo, PreviewModuleClassCard, NextPrevNavigation } from "../../components/features/CourseDetail";

import { DebateItem } from "../../components/features/Debate";
import { Typography, PageContainer, InnerContentContainer, GreyContainer, QuizContainer, ContentLoadingError, ClassFileUploader, ClassSentFilesList, VideoChapters } from "../../components";
import { ModuleCardWrapper } from "../../components/elements";
import CourseDetailDebates from "./CourseDetailDebates";

import { IoIosClose } from "react-icons/io";

import loadingGif from "../../assets/images/loading.gif";

import {
	CourseDetailContainer,
	ClassContentWrapper,
	VideoWrapper,
	ModulesListWrapper,
	ShowModulesListButton,
	CloseModulesListButton,
	CloseModulesListButtonWrapper,
	LeiaMaisButtonWrapper,
} from "./CourseDetailStyled";
import { IVideoStats, Material, QuizAnswerResponse } from "../../types/course";
import { useCourseDetail } from "../../context/courseDetail/courseDetailContext";
// import { isMobile } from "../../isMobile";
import { DebateProvider } from "../../context/debate/debateContext";
import { IssueCertificateLink } from "../../components/elements/IssueCertificateLink";
import { Link } from "react-router-dom";
import VideoPlayerEventsService from "../../services/videoPlayerEvents.service";
import { useLanguageContext } from "../../context/language/languageContext";

export interface ICourseContainerProps {
	hasOneButton?: boolean;
	hasTwoButton?: boolean;
}

const CourseDetail: React.FC = ({ ...props }) => {
	const {
		loading, //
		course,
		openModuleList,
		showModuleList,
		closeModuleList,
		activeModule,
		activeModuleClass,
		isCourseFinished,
		courseCertificateUrl,
		sealAvailable,
		sealUrl,
	} = useCourseDetail()!;
	// const [hackKey, setHackKey] = useState(0);
	const { getLanguageStrings } = useLanguageContext();
	const [truncateDescription, setTruncateDescription] = useState(true);
	const [showTruncateButton, setshowTruncateButton] = useState(true);
	const [previousAnswers, setPreviousAnswers] = useState<QuizAnswerResponse[]>([]);
	const [hasScrolled, setHasScrolled] = useState(false);
	const [visualizationID, setVisualizationID] = useState<number>();
	const [playing, setPlaying] = useState(false);

	const [videoStats, setVideoStats] = useState<IVideoStats>({
		isPaused: true,
		tempoAtual: 0,
		tempoPause: 0,
		ultimoTempoConhecido: 0,
	});

	const playerRef = useRef<ReactPlayer>(null);

	const toggleTruncateDescription = useCallback(() => {
		setTruncateDescription(!truncateDescription);
	}, [truncateDescription]);

	const addAnswerToList = (answer: QuizAnswerResponse) => {
		// console.log("ANSWER FROM PARENT => ", answer);
		setPreviousAnswers([...previousAnswers, answer]);
	};

	useEffect(() => {
		// console.log("ACTIVE MODULE => ", activeModule);

		if (activeModuleClass) {
			if (activeModuleClass.isQuiz && activeModule?.quizObject) {
				setPreviousAnswers(activeModule.quizObject.avaliacoes_respondidas);
			}

			if (
				// Cortar a descrição apenas caso haja vídeo na aula. Se não houver, deixar o texto direto
				activeModuleClass.videoUrl &&
				activeModuleClass.description.length > 1600
			) {
				setTruncateDescription(true);
				setshowTruncateButton(true);
			} else {
				setTruncateDescription(false);
				setshowTruncateButton(false);
			}
		}
	}, [activeModuleClass]);

	const handleModuleListPosition = useCallback(
		_.debounce(() => {
			let colunaModulos = document.querySelector("#colunaModulos");
			if (!colunaModulos) return;
			if (hasScrolled && window.pageYOffset > 100) return;
			if (window.pageYOffset === 0) {
				colunaModulos.classList.remove("scroll");
				setHasScrolled(false);
			} else {
				colunaModulos.classList.add("scroll");
				setHasScrolled(true);
			}
		}, 20),
		[]
	);

	useEffect(() => {
		window.addEventListener("scroll", handleModuleListPosition);
		// window.addEventListener("orientationchange", function () {
		// });
	}, []);

	const handleStartVideo = async () => {
		setPlaying(true);
		//@ts-ignore
		const inicio: number = Math.round(playerRef.current.getCurrentTime());
		//@ts-ignore
		const tempoTotal: number = Math.round(playerRef.current.getDuration());

		const ultTempo: number = videoStats.tempoAtual;

		setVideoStats({
			...videoStats,
			isPaused: false,
			tempoAtual: inicio,
			ultimoTempoConhecido: ultTempo === inicio ? null : ultTempo,
		});

		const videoPlayerEventsService = new VideoPlayerEventsService();

		let response;

		if (!visualizationID) {
			response = await videoPlayerEventsService.iniciarAssistir(activeModuleClass?.id, inicio, tempoTotal, videoStats);
			setVisualizationID(response.id);
		} else {
			response = await videoPlayerEventsService.iniciarAssistir(activeModuleClass?.id, inicio, tempoTotal, videoStats, visualizationID);
			if (response.id !== visualizationID) {
				setVisualizationID(response.id);
			}
		}
	};

	const handlePauseVideo = async () => {
		setPlaying(false);
		if (!visualizationID) return;
		//@ts-ignore
		const tempoPause: number = Math.round(playerRef.current.getCurrentTime());
		//@ts-ignore
		const tempoTotal: number = Math.round(playerRef.current.getDuration());

		setVideoStats({
			isPaused: true,
			tempoPause,
			tempoAtual: tempoPause,
			ultimoTempoConhecido: tempoPause,
		});

		const videoPlayerEventsService = new VideoPlayerEventsService();

		await videoPlayerEventsService.pausarVideo(visualizationID, tempoPause, tempoTotal, videoStats);
	};

	const handleOnProgressVideo = async (isFinished: boolean) => {
		if (isFinished) {
			setPlaying(false);
		}
		if (!visualizationID) return;
		if (videoStats.isPaused) return;
		//@ts-ignore
		const tempoAtual: number = Math.round(playerRef.current.getCurrentTime());

		setVideoStats({
			...videoStats,
			isPaused: false,
			tempoAtual,
			ultimoTempoConhecido: tempoAtual,
		});

		const videoPlayerEventsService = new VideoPlayerEventsService();

		await videoPlayerEventsService.assistirVideo(visualizationID, tempoAtual, isFinished, videoStats);
	};

	const pauseVideo = async () => {
		setPlaying(false);
	};

	return (
		<PageContainer hideBanner pageTitle={course?.name} showBreadcrumb showSearch={false} searchHelpText='Neste curso'>
			{loading ? (
				<Box display='flex' alignItems='center' justifyContent='center'>
					<img src={loadingGif} alt='' />
				</Box>
			) : (
				<>
					{course ? (
						<>
							<ShowModulesListButton onClick={() => openModuleList()}> {getLanguageStrings().courses.seeMoreClasses} </ShowModulesListButton>
							<GreyContainer>
								<InnerContentContainer>
									{" "}
									<CourseDetailContainer
										hasOneButton={isCourseFinished && course.isComplete && (!!courseCertificateUrl || sealAvailable)}
										hasTwoButton={sealAvailable && isCourseFinished && course.isComplete && courseCertificateUrl && courseCertificateUrl !== "#" && course.hasCertificate ? true : false}>
										<Box gridColumn='1' id='colunaModulos' className='colunaModulos'>
											{course && (
												<div>
													{isCourseFinished && course.isComplete && courseCertificateUrl && courseCertificateUrl !== "#" ? (
														<>
															<IssueCertificateLink certificateUrl={courseCertificateUrl} />
														</>
													) : (
														""
													)}
													{sealAvailable && isCourseFinished && course.isComplete && sealUrl ? (
														<>
															<IssueCertificateLink isSeal certificateUrl={sealUrl} />
														</>
													) : (
														""
													)}
												</div>
											)}
											{showModuleList && (
												<ModulesListWrapper>
													{window.innerWidth < 900 && (
														<CloseModulesListButtonWrapper>
															<CloseModulesListButton
																onClick={() => {
																	closeModuleList();
																}}>
																<IoIosClose size={30} color='#FFF' />
															</CloseModulesListButton>
														</CloseModulesListButtonWrapper>
													)}
													{course.modules?.map((module) => (
														<ModuleCardWrapper hasComments={module.hasComments} showArrow key={module.id} module={module} />
													))}
												</ModulesListWrapper>
											)}
										</Box>
										<Box gridColumn='2' gridRow='1/3' paddingLeft={{ xs: "0px", md: "21px" }} width='100%'>
											{!activeModule ? (
												<div>{getLanguageStrings().courses.course.noModules}.</div>
											) : (
												<>
													{activeModule.name ? (
														<>
															{activeModuleClass && (
																<>
																	<ViewClassHeader activeLesson={activeModuleClass} name={activeModuleClass.name} moduleName={activeModule.nome} releaseDate={activeModuleClass.releaseDate} />
																	<ClassContentWrapper>
																		{activeModuleClass.videoUrl && (
																			<VideoWrapper>
																				<ReactPlayer
																					controls //
																					width='100%'
																					height='100%'
																					url={activeModuleClass.videoUrl}
																					pip
																					progressInterval={10000}
																					ref={playerRef}
																					onPlay={handleStartVideo}
																					onPause={handlePauseVideo}
																					onProgress={() => handleOnProgressVideo(false)}
																					onEnded={() => handleOnProgressVideo(true)}
																					playing={playing}
																				/>
																			</VideoWrapper>
																		)}
																		{!activeModuleClass.isQuiz && <NextPrevNavigation />}
																		<Box
																			display='flex'
																			flexDirection={{
																				xs: "column",
																				sm: "column",
																				md: "row",
																			}}
																			style={{ background: "#FFF", position: "relative" }}
																			justifyContent='space-between'
																			padding='31px 20px 31px 20px'>
																			<div>
																				{activeModuleClass.isQuiz && (
																					<>
																						{activeModule.quizObject ? (
																							<>
																								<p>
																									{activeModule.quizObject?.chances}
																									{getLanguageStrings().courses.course.quiz.answersText}
																								</p>
																								<p>
																									{getLanguageStrings().courses.course.quiz.prevResults}
																									<br />
																									<br />
																									{previousAnswers.map((av: any, index) => {
																										return (
																											<p>
																												{getLanguageStrings().courses.course.quiz.quizTry} {index + 1}: <b>{av.qtd_acertos} / {av.qtd_questoes}</b> -{" "}
																												<b>
																													{getLanguageStrings().courses.course.quiz.grade}:
																													{av.nota}%
																												</b>
																											</p>
																										);
																									})}
																									<br />
																								</p>
																							</>
																						) : (
																							""
																						)}
																						{activeModuleClass.description && (
																							<p style={{ marginBottom: "-10px" }}>
																								<b>{getLanguageStrings().courses.course.quiz.description}</b>
																							</p>
																						)}
																					</>
																				)}
																				{/*  */}
																				{activeModuleClass.videoUrl && activeModuleClass.timeline && activeModuleClass.timeline.length > 0 &&
																					<VideoChapters timeline={activeModuleClass.timeline} seekFunction={(to) => playerRef.current?.seekTo(to)} />
																				}
																				<CourseInfo
																					name={activeModuleClass.isQuiz ? "" : activeModuleClass.name}
																					description={activeModuleClass.description}
																					truncateDescription={truncateDescription}
																				/>
																				{activeModuleClass.acceptsUpload && (
																					<>
																						<ClassFileUploader maxFiles={activeModuleClass.maxUploadableFiles} classId={activeModuleClass.id} />
																					</>
																				)}
																				{showTruncateButton && (
																					<LeiaMaisButtonWrapper>
																						<button
																							onClick={() => {
																								toggleTruncateDescription();
																							}}>
																							{truncateDescription ? getLanguageStrings().courses.course.readMore : getLanguageStrings().courses.course.readLess}
																						</button>
																					</LeiaMaisButtonWrapper>
																				)}
																			</div>

																			{activeModuleClass.materials.length > 0 && (
																				<MaterialsList>
																					<Typography variant='title' size='18px' margin='0 0 21px 0' fontWeight='bold'>
																						{getLanguageStrings().courses.course.downloadsLesson}
																					</Typography>
																					{activeModuleClass.materials.map((material: Material) => (
																						<li key={material.id}>
																							<a href={material.url} target='_blank' title={material.name}>
																								{material.name}
																							</a>
																						</li>
																					))}
																				</MaterialsList>
																			)}
																		</Box>
																		<Box style={{ background: "#FFF", position: "relative", marginTop: "-50px" }} padding='0px 20px 31px 20px'>
																			{activeModuleClass.isQuiz && <QuizContainer onAnswer={addAnswerToList} moduleId={activeModule.id} quizObject={activeModule.quizObject} />}
																		</Box>
																	</ClassContentWrapper>
																	<DebateProvider>
																		{!activeModuleClass.isQuiz && (
																			<CourseDetailDebates timestamp={activeModuleClass.timestamp} course={course} activeModuleClass={activeModuleClass} onAction={pauseVideo} />
																		)}
																	</DebateProvider>
																</>
															)}
														</>
													) : (
														<>
														</>
													)}
												</>
											)}
										</Box>
									</CourseDetailContainer>
								</InnerContentContainer>
							</GreyContainer>
						</>
					) : (
						<GreyContainer>
							<InnerContentContainer>
								<ContentLoadingError refresh={() => window.location.reload()} loadedResourceErrorMsg='as aulas' />
							</InnerContentContainer>
						</GreyContainer>
					)}
				</>
			)}
		</PageContainer>
	);
};

export default CourseDetail;
