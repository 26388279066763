import axios from "axios";
import jsonToFormData from "json-form-data";
import { headers, BASE_URL } from "./config";
// import qs from 'querystring';
import LoginService from "./login.service";

import { getToken } from "./auth";

const api = axios.create({
	baseURL: `${BASE_URL}/`,
	timeout: 10000,
});

api.interceptors.request.use(async (config) => {
	//console.group("Request interceptor");
	//console.log("config from api", config);
	//config.headers["Access-Control-Allow-Origin"] = "*";
	const token = getToken();
	// const token = "";

	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	config.withCredentials = true;
	//console.log("DATA API", config.data);

	//config.headers["Content-Type"] = "multipart/form-data";

	//console.log("headers", config.headers);

	if (config.data && config.headers["Content-Type"] === "multipart/form-data") {
		const options = {
			initialFormData: new FormData(),
			showLeafArrayIndexes: true,
			includeNullValues: false,
		};
		config.data = jsonToFormData(config.data, options);
	}

	//console.groupEnd();

	return config;
});

api.interceptors.response.use(
	(response) =>
		// Return a successful response back to the calling service
		response,
	(error) => {
		console.group("Response Error Interceptor");
		console.log("ERROR----", error);
		console.log("ERROR----cong", error.config);
		console.log("ERROR----URL", error.config.url);

		const originalReq = error.config;
		if (error.config && !error.config._retry) {
			originalReq._retry = true;

			// Return any error which is not due to authentication back to the calling service
			if ((error.response.status !== 401 && error.response.status !== 403) || error.config.url === "token/") {
				return new Promise((resolve, reject) => {
					reject(error);
				});
			}
			// console.error(error.response);
			// console.error(error.response.status);

			// Logout user if token refresh didn't work or user is disabled
			if (error.config.url === "token/refresh/" || error.response.message === "Account is disabled.") {
				localStorage.removeItem("token");
				localStorage.removeItem("refresh_token");
				const redirectLogin = `${window.location.origin}/`;
				window.location = redirectLogin;
			}

			// Try request again with new token
			const loginService = new LoginService();

			return loginService
				.refreshToken()
				.then(() => {
					// New request with new token
					const { config } = error;
					return new Promise((resolve, reject) => {
						api
							.request(config)
							.then((response) => {
								resolve(response);
							})
							.catch((err) => {
								reject(err);
							});
					});
				})
				.catch((errors) => {
					Promise.reject(errors);
				});
		}
		console.groupEnd();
		// const { status } = error.response;
		// const redirect = `${window.location.origin}/${status}`;
		// window.location = redirect;
	}
);

export default api;
