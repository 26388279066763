import React from "react";
import { IoMdLock, IoIosArrowUp } from "react-icons/io";
import { useLanguageContext } from "../../../../../context/language/languageContext";

// import {
// 	// /StudentsAvatarGroup, CircularProgressIndicator,
// 	// IconWrapper,
// } from "../../..";
import { Module } from "../../../../../types/course";
import {
	CardTitle,
	CardSubtitle,
	CardFooter,
	CardHeader,
	//CardHeaderHelpText
} from "../CardStyleBase";

import { ModuleCardContainer, CommentsTag } from "./ModuleCardStyled";

export interface Props extends React.HTMLAttributes<HTMLElement> {
	module: Module;
	action?: any;
	isOpen?: boolean;
	showArrow?: boolean;
}

// const BlockedIcon: React.FC = () => <IconWrapper backgroundColor='#FFF' size='32px' rounded icon={<IoMdLock size='60%' color='#000' />} />;
const ModuleCardWrapper: React.FC<Props> = ({ module, showArrow, action, isOpen, ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext();
	return (

		<ModuleCardContainer
			onClick={() => {
				action(module);
			}}
			{...props}
			module={module}
			// action={action ? action : "no-action"}
			isOpen={isOpen}
			showArrow={showArrow}>
			{module && (
				<>
					<CardHeader>
						<CardTitle>
							<h2 className='title'>{module.name}</h2>
							{module.releaseDate ? <CardSubtitle>{getLanguageStrings().courses.course.released} {module.releaseDate}</CardSubtitle> : <CardSubtitle>A ser liberado</CardSubtitle>}
							{module.hasComments && <CommentsTag>{getLanguageStrings().courses.course.newComments}</CommentsTag>}
						</CardTitle>
					</CardHeader>
					<CardFooter>
						{isOpen && (
							<>
								{showArrow && (
									<div className='arrowWrapper'>
										<IoIosArrowUp size='32px' />
									</div>
								)}
							</>
						)}
					</CardFooter>
				</>
			)}
		</ModuleCardContainer>
	)
};

ModuleCardWrapper.defaultProps = {
	isOpen: false,
};

export { ModuleCardWrapper as ModuleCard };
