import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Box } from "@material-ui/core";
import { FormHandles } from "@unform/core";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import { RichTextInput } from "../../..";
import { Button } from "../../../elements";
import { useCreateFromModal } from "../../../../hooks/useCreateFromModal";
import CourseClassDebatesService from "../../../../services/courseClassDebatesService.service";
import { useLanguageContext } from "../../../../context/language/languageContext";

export interface Props {
	lessonId: number;
	debateData?: any;
	icon?: React.FC<{ size?: string; onClick?: () => any }>;
	successCallback?: () => void;
	onAction?: () => void;
}

const CreateDebateWrapper: React.FC<Props> = ({ lessonId, debateData, icon: Icon, successCallback, onAction, ...props }: Props) => {
	const [open, setOpen] = useState(false);
	const isUpdate = !!debateData;
	const formRef = useRef<FormHandles>(null);
	const schema = Yup.object().shape({
		descricao: Yup.string().required("Este campo é obrigatório."),
	});

	const handleSuccess = (data: any) => {
		setOpen(false);
		if (successCallback) {
			successCallback();
		}
	};
	const { handleSubmit, loading } = useCreateFromModal({
		formRef,
		schema,
		isUpdate,
		item: debateData,
		service: new CourseClassDebatesService(lessonId),
		successMessage: `Debate ${isUpdate ? "Alterado" : "Criado!"}`,
		handleSuccess,
	});

	const handleClickOpen = () => {
		if (onAction) {
			onAction()
		}
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const submitForm = () => {
		formRef?.current?.submitForm();
	};
	const { getLanguageStrings } = useLanguageContext();
	return (
		<>
			<Box display='flex' justifyContent='flex-end' alignItems='center'>
				{Icon === undefined && (
					<Button variant='add' onClick={handleClickOpen}>
						{getLanguageStrings().comments.new}
					</Button>
				)}
				{Icon && <Icon size='20px' onClick={handleClickOpen} />}
			</Box>
			<ModalBase loading={loading} open={open} title={isUpdate ? getLanguageStrings().comments.editModalTitle : getLanguageStrings().comments.createModalTitle} handleClose={handleClose} saveAction={submitForm}>
				<Form
					ref={formRef}
					onSubmit={handleSubmit}
					initialData={{
						descricao: debateData?.description,
					}}>
					<Box display='grid' gridTemplateColumns='1fr' gridTemplateRows='3fr' gridRowGap='20px' gridColumnGap='20px'>
						<RichTextInput name='descricao' label={getLanguageStrings().comments.description} />
					</Box>
				</Form>
			</ModalBase>
		</>
	);
};

export { CreateDebateWrapper as CreateDebate };
