import React, { useEffect } from "react";

import { NextPrevNavigationContainer, NextPrevButton } from "./NextPrevNavigationStyled";

// import { Module, ModuleClass } from "../../../../types/course";
import { useCourseDetail } from "../../../../context/courseDetail/courseDetailContext";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useLanguageContext } from "../../../../context/language/languageContext";

export interface Props { }

const NextPrevNavigationWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const {
		//
		updateActiveModuleClass,
		updateActiveModule,
		nextLesson,
		nextModule,
		previousLesson,
		previousModule,
	} = useCourseDetail()!;

	const { getLanguageStrings } = useLanguageContext();
	const setNextOrPreviousLesson = (direction: "next" | "prev") => {


		if (direction === "next") {
			if (nextLesson) {
				if (nextLesson.isReleased) {
					updateActiveModuleClass(nextLesson);
				} else if (nextModule && nextModule.released) {
					updateActiveModule(nextModule);
					updateActiveModuleClass(nextModule.moduleClasses[0]);
				} else if (!nextLesson.isReleased) return;
			}
		}
		if (direction === "prev") {
			//console.log("prev from button", previousLesson);
			if (previousLesson) {
				updateActiveModuleClass(previousLesson);
			} else if (previousModule) {
				updateActiveModule(previousModule);
				updateActiveModuleClass(previousModule.moduleClasses[0]);
			}
		}
	};

	return (
		<NextPrevNavigationContainer {...props}>
			<NextPrevButton action='prev' disabled={!previousLesson && !previousModule} onClick={() => setNextOrPreviousLesson("prev")}>
				<FiChevronLeft size={20} />
				{getLanguageStrings().courses.course.buttons.prevLesson}
			</NextPrevButton>
			<NextPrevButton action='next' disabled={(nextLesson && !nextLesson?.isReleased) || (!nextLesson && !nextModule)} onClick={() => setNextOrPreviousLesson("next")}>
				<FiChevronRight size={20} />
				{getLanguageStrings().courses.course.buttons.nextLesson}
			</NextPrevButton>
		</NextPrevNavigationContainer>
	);
};

NextPrevNavigationWrapper.defaultProps = {};

export { NextPrevNavigationWrapper as NextPrevNavigation };
