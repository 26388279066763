import React, { useCallback, useEffect, useRef, useState } from "react";
import { StudentProfileMapContainer } from "./StudentProfileMapStyled";
import { EsriProvider } from "leaflet-geosearch";

import L from "leaflet";

import { Map, TileLayer, Marker } from "react-leaflet";

import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import StudentProfileService, { IStudentApiResponse } from "../../../services/student.profile.service";
import { useLanguageContext } from "../../../context/language/languageContext";

const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export interface Props {
	lat: number | null;
	lng: number | null;
	zoom?: number;
	address?: string;
	editing?: boolean;
	editable?: boolean;
	studentData?: {
		name: string;
		id: number;
	};
}

const StudentProfileMapWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext();
	const [coords, setCoords] = useState({
		lat: 0,
		long: 0,
	});
	const [address, setAddress] = useState("");
	const [editing, setEditing] = useState(false);
	const markerRef = useRef(null);

	const getCoordinates = useCallback(async () => {
		if (props.lat === null && props.lng === null && coords.long === 0 && coords.lat === 0) {
			// console.log("NÃO DEVIA ESTAR AQUI", [props.lat, props.lng]);
			try {
				const esriProvider = new EsriProvider();
				const response = await esriProvider.search({
					query: address ? address : "",
				});

				// console.log("ESRI RESPONSE ==> ", response);

				//console.log("ADDRESS FROM MAP", { lt: props.lat, ln: props.lng, address: address });

				if (response.length) {
					const result = response[0];
					setCoords({
						lat: parseFloat(result.y.toString()),
						long: parseFloat(result.x.toString()),
					});
				}
			} catch (error) {
				console.error("Erro ao buscar localização: ", error);
			}
		} else if (props.lat !== null && props.lng !== null) {
			//console.log("Prop Válido", [props.lat, props.lng]);

			setCoords({
				lat: props.lat,
				long: props.lng,
			});
		}
	}, [address, props.lat, props.lng, coords]);

	const setNewCoordinates = useCallback(
		async (e) => {
			setCoords({
				lat: e.target._latlng.lat,
				long: e.target._latlng.lng,
			});
			//console.log({ ltd: e.target._latlng.lat, lng: e.target._latlng.lng });

			const studentProfileService = new StudentProfileService();
			const aluno = await studentProfileService.list();

			if (aluno.nome) {
				//console.log("UPDATE MAP");
				const response = await studentProfileService.update(
					aluno.id,
					{
						nome: aluno.nome,
						ltd: e.target._latlng.lat,
						lng: e.target._latlng.lng,
					},
					true // isUser
				);
				//console.log("RESPONSE FROM MAP UPDATE ==> ", response);
			}

			//getCoordinates(e.target._latlng.lat, e.target._latlng.lng);
		},
		[coords]
	);

	// const logLatLong = useCallback(() => {
	// 	console.log("LOG LATLONG", coords);
	// }, [coords]);

	//const editMap = useCallback(async () => {}, []);

	useEffect(
		() => {
			// console.log("PROP LAT LNG", [props.lat, props.lng]);
			// console.log("PROP ADDRESS", props.address || "SEM ENDEREÇO");
			setAddress(props.address || "");
			getCoordinates();
		},
		// eslint-disable-next-line
		[props.address, address]
	);

	return (
		<StudentProfileMapContainer {...props}>
			{props.editable && (
				<button className='editPill' onClick={() => setEditing(!editing)}>
					{editing ? "Ok" : getLanguageStrings().profile.editMap}
				</button>
			)}
			<Map zoomControl center={[coords.lat, coords.long]} zoom={props.zoom ? props.zoom : 15} doubleClickZoom={true} keyboard={false} scrollWheelZoom={true}>
				<TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
				<Marker ref={markerRef} position={[coords.lat, coords.long]} draggable={editing} ondragend={setNewCoordinates} />
			</Map>
		</StudentProfileMapContainer>
	);
};

export { StudentProfileMapWrapper as StudentProfileMap };
