import React, { useCallback, useEffect } from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useUserContext } from "../../../context/user/AuthContext";

import { IoIosMenu } from "react-icons/io";
import { FaMedal } from "react-icons/fa";
import {
	IconWrapper, //
	SidebarIconWrapper,
	ComunitySidebarIcon,
	CourseSidebarIcon,
	DashboardSidebarIcon,
	MultimidiaSidebarIcon,
	DownloadsSidebarIcon,
	ArticlesSidebarIcon,
	SociautismIcon,
	PosIcon,
} from "../../elements/Icons";
// import { useUserContext } from "../../../context/user/AuthContext";
import * as CustomTheme from '../../../styles/theme'

import { trackGA } from "../../../helpers/trackEvent";
import {
	SidebarContainer,
	// MenuHeight
} from "./SidebarStyled";
import { useLanguageContext } from "../../../context/language/languageContext";

export interface Props {
	logoUrl: string;
}


const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			width: drawerWidth,
			borderColor: "#f1f1f1",
			background: CustomTheme.default.color.primary,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			borderColor: "#f1f1f1",
			background: CustomTheme.default.color.primary,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: "75px",
			[theme.breakpoints.up("sm")]: {
				width: "75px",
			},
		},
		NavLink: {
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			fontWeight: 700,
			width: "100%",
			height: "100%",
			color: "#013A7B",
			fontSize: "13px",
		},
		paper: {
			backgroundColor: "#f1f1f1",
		},
	})
);

const SidebarWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const classes = useStyles();
	const { getLanguageStrings } = useLanguageContext();
	const { isPlus, showPos, userData } = useUserContext();
	const [open, setOpen] = React.useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	const menuItems = [
		// {
		// 	name: "Início",
		// 	link: "/dashboard",
		// 	restrictedToSubscribers: true,
		// 	icon: <DashboardSidebarIcon color='#013A7B' />,
		// },
		{
			name: getLanguageStrings().titles.articles,
			link: "/artigos",
			restrictedToSubscribers: false,
			icon: <ArticlesSidebarIcon color='#013A7B' />,
		},
		// {
		// 	name: "Notícias",
		// 	link: "/noticias",
		// 	restrictedToSubscribers: true,
		// 	icon: <ComunitySidebarIcon color='#013A7B' />,
		// },
		{
			name: getLanguageStrings().titles.courses,
			link: "/cursos",
			restrictedToSubscribers: false,
			icon: <CourseSidebarIcon color='#013A7B' />,
		},
		{
			name: getLanguageStrings().titles.downloads,
			link: "/materiais",
			restrictedToSubscribers: false,
			icon: <DownloadsSidebarIcon color='#013A7B' />,
		},
		// {
		// 	name: "Séries",
		// 	link: "/multimidias",
		// 	restrictedToSubscribers: true,
		// 	icon: <MultimidiaSidebarIcon color='#013A7B' />,
		// },
		// {
		// 	name: "Sociautism",
		// 	link: "/sociautism",
		// 	restrictedToSubscribers: true,
		// 	icon: <SociautismIcon color='#013A7B' />,
		// },
	];

	const restrictedItem = useCallback((item) => {
		if (item.restrictedToSubscribers) {
			if (isPlus()) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}, []);

	useEffect(() => {
		//console.log("userdata", userData.grupos.split(","));
	}, []);

	return (
		<SidebarContainer open={open}>
			<Hidden mdUp>
				<div className={isPlus() ? "handleDrawer isPlus" : "handleDrawer"} onClick={() => toggleDrawer()}>
					<IconWrapper filled={false} icon={<IoIosMenu size='30px' />} />
				</div>
				<Drawer classes={{ paper: classes.paper }} variant='temporary' open={open} onClick={() => toggleDrawer()}>
					<List>
						{menuItems.map((item, index) => (
							<React.Fragment key={index}>
								{restrictedItem(item) ? (
									<>
										<ListItem key={item.name}>
											<NavLink exact to={item.link} className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", item.name)}>
												<ListItemIcon>
													<SidebarIconWrapper svgIcon={item.icon} />
												</ListItemIcon>
												<ListItemText
													primary={item.name}
													secondary={item.restrictedToSubscribers && item.name !== "Início" ? "Plus" : ""}
													secondaryTypographyProps={{ style: { color: "#f2c94c", fontWeight: "bold" } }}
												/>
											</NavLink>
										</ListItem>
									</>
								) : (
									""
								)}
							</React.Fragment>
						))}
					</List>
				</Drawer>
			</Hidden>

			<Hidden smDown>
				<Drawer
					variant='permanent'
					onMouseEnter={() => setOpen(true)}
					onMouseLeave={() => setOpen(false)}
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}>
					<List>
						{/* Sim, esse primeiro item ta aqui só pq eu não quis dar o espaço pra tirar o primeiro item de baixo do header. Me julgue. */}
						<ListItem>
							<NavLink exact to='/dashboard' className={classes.NavLink} activeClassName='active'>
								<ListItemIcon>
									<SidebarIconWrapper svgIcon={<DashboardSidebarIcon color='#013A7B' />} />
								</ListItemIcon>
								<ListItemText primary='Dashboard' />
							</NavLink>
						</ListItem>
						{menuItems.map((item, index) => (
							<React.Fragment key={index}>
								{restrictedItem(item) ? (
									<>
										<ListItem key={item.name}>
											<NavLink exact to={item.link} className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", item.name)}>
												<ListItemIcon>
													<SidebarIconWrapper svgIcon={item.icon} />
												</ListItemIcon>
												<ListItemText
													primary={item.name}
													secondary={item.restrictedToSubscribers && item.name !== "Início" ? "Plus" : ""}
													secondaryTypographyProps={{ style: { color: "#f2c94c", fontWeight: "bold" } }}
												/>
											</NavLink>
										</ListItem>
									</>
								) : (
									""
								)}
							</React.Fragment>
						))}
					</List>
				</Drawer>
			</Hidden>
		</SidebarContainer>
	);
};

// SidebarWrapper.defaultProps = {
//   background: '#FFF'
// }

export { SidebarWrapper as Sidebar };
