import React, { ReactNode, useCallback, useEffect, useState } from "react";
// import { IoIosNotifications } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { HeaderContainer } from "./HeaderStyled";
import { CircularAvatarPhoto } from "../../elements/CircularAvatarPhoto";
import { isMobile } from "../../../isMobile";
import { useUserContext } from "../../../context/user/AuthContext";

import { IoIosNotifications } from "react-icons/io";

import NotificationsService from "../../../services/notifications.service.js";
import logoRDMedicine from "../../../assets/images/logo_rd_medicine_horizontal.png";
import profilePicPlaceholder from "../../../assets/images/profile_avatar_placeholder.png";
import { useLanguageContext } from "../../../context/language/languageContext";

export interface Props {
	background?: "primary" | "default" | undefined;
	children?: ReactNode;
	title: string;
	username: string;
	subTitle?: string;
	logoUrl?: string;
	userImageUrl?: string;
	notificationCount?: number;
	showUserAvatar?: boolean;
	showSchoolLogo?: boolean;
}

const HeaderWrapper: React.FC<Props> = ({ children, ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext()!;
	const { userData, handleLogout } = useUserContext();
	//console.log("USER DATA FROM HEADER ==> ", userData);
	const history = useHistory();
	const logout = useCallback(() => {
		handleLogout();
		history.push("/");
	}, []);
	const [notificationsCount, setNotificationsCount] = useState(0);
	const getNotificationsCount = useCallback(async () => {
		const notificationsService = new NotificationsService({ unread: true });
		const response = await notificationsService.list();
		//console.log("Notifications Response", response);
		setNotificationsCount(response.count);
	}, [notificationsCount]);

	const isPlus = useCallback(() => {
		const userGroups = userData.grupos.split(",");
		if (userGroups.includes("Alunos") || userGroups.includes("NMAAlunos")) {
			return true;
		}
		return false;
	}, [userData]);

	useEffect(() => {
		if (!localStorage.getItem("userImg")) {
			localStorage.setItem("userImg", userData.foto);
		}
		getNotificationsCount();
		//alert(navigator.userAgent);
	}, []);

	return (
		<HeaderContainer {...props} className={isPlus() ? "isPlus" : ""}>
			<div className='schoolInfo'>
				{props.logoUrl && (
					<div className='schoolLogo'>
						<Link to='/'>
							<CircularAvatarPhoto avatarImg={props.logoUrl} avatarName={props.title} borderColor='primary' borderType='single' innerBorderSize='3px' size={isMobile.any() ? "40px" : "45px"} darken />
						</Link>
					</div>
				)}
				{isMobile.any() ? (
					<h1 className='logoNma'>
						<Link to='/'>{isPlus() ? <img src={logoRDMedicine} alt='' /> : <img src={logoRDMedicine} alt='' />}</Link>
					</h1>
				) : (
					<h1 className={props.showSchoolLogo ? "showLogo" : "hideLogo"}>
						{getLanguageStrings().appName}
						{/* {isPlus() && <span className='plus'> Aluno Plus</span>} */}
						<small>{getLanguageStrings().subtitle}</small>
						{/* {props.subTitle ? <small>{props.subTitle}</small> : ""} */}
					</h1>
				)}
				{/*  */}
			</div>
			<div className='studentInfo'>
				{props.showUserAvatar && (
					<div className='nameLogout'>
						<span className='nomeAluno'>{userData.nome_aluno}</span>
						<button
							className='logoutButton'
							onClick={() => {
								logout();
							}}>
							{getLanguageStrings().header.logout}
						</button>
					</div>
				)}
				{props.showUserAvatar && (
					<Link to='/meu-perfil'>
						<CircularAvatarPhoto
							avatarImg={
								userData.foto && userData.foto !== "#"
									? userData.foto
									: localStorage.getItem("userImg") && localStorage.getItem("userImg") !== "#"
										? localStorage.getItem("userImg")
										: profilePicPlaceholder
							}
							avatarName={userData.name}
							borderColor='white'
							borderType='single'
							innerBorderSize='3px'
							size={isMobile.any() ? "40px" : "45px"}
							darken
						/>
					</Link>
				)}
				<div className='notificationBell'>
					<Link to='/notificacoes'>
						<IoIosNotifications size={20} color='#FFF' />
					</Link>
				</div>
				{notificationsCount > 0 && (
					<>
						<span className='notificationCount'>{notificationsCount > 9 ? "9+" : props.notificationCount}</span>
					</>
				)}
			</div>
			{children}
		</HeaderContainer>
	);
};

HeaderWrapper.defaultProps = {
	background: "primary",
	showUserAvatar: true,
	showSchoolLogo: true,
};

export { HeaderWrapper as Header };
