import { ptbr } from "./pt-br";
import { enus } from "./en-us";

import { ILanguageStringList } from "../types/language";

const langCodes = [
	"ptbr", // add new languages here
	"enus",
] as const;

export type TLanguage = typeof langCodes[number];

type TLanguagesObject = {
	[k in TLanguage]: ILanguageStringList;
};

export function isLanguage(l: string): l is TLanguage {
	return langCodes.includes(l as TLanguage);
}

export const languages: TLanguagesObject = {
	ptbr, // and here
	enus,
};
