import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { motion } from "framer-motion";
import CourseService from "../../../../services/course.service";
import { Button, IconWrapper, Loading } from "../../../elements";
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { ModuleClass } from "../../../../types/course";
import { useCourseDetail } from "../../../../context/courseDetail/courseDetailContext";
import { useLanguageContext } from "../../../../context/language/languageContext";
//import { isNullOrUndefined } from "util";

export interface Props {
	lesson: ModuleClass;
}

const CompleteLessonButtonWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const { course, activeModule, updateActiveModuleClass, updateActiveModule, activeModuleClass, getCourseDetails } = useCourseDetail()!;
	const { lesson } = props;
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		setSuccess(false);
		setLoading(false);
	}, [lesson]);

	const getNextLesson = async () => {
		if (activeModule && activeModuleClass) {
			//tentar pegar proxima aula do modulo
			const activeLessonIndex = activeModule?.moduleClasses.findIndex((lesson) => {
				return lesson.id === activeModuleClass.id;
			});

			if (activeModule?.moduleClasses[activeLessonIndex + 1]) {
				return { nextLesson: activeModule?.moduleClasses[activeLessonIndex + 1], nextModule: null };
			}
			//se nao tiver tentar pegar 1 aula do proximo modulo
			const activeModuleIndex =
				course?.modules?.findIndex((module) => {
					return module.id === activeModule.id;
				}) || 0;

			if (course?.modules[activeModuleIndex + 1]) {
				const nextModule = course?.modules[activeModuleIndex + 1];
				if (nextModule.moduleClasses[0]) {
					return {
						nextLesson: nextModule.moduleClasses[0],
						nextModule: nextModule,
					};
				}
			}
		}

		const courseService = new CourseService();
		await courseService.mark_as_last_seen(lesson.id);

		return { nextLesson: null, nextModule: null };
	};

	const setNextLesson = async () => {
		const { nextLesson, nextModule } = await getNextLesson();

		// console.log("NEXT MODULE", nextModule);

		if (nextLesson && nextLesson.isReleased) {
			updateActiveModuleClass(nextLesson);
		}
		if (nextModule && nextModule.released) {
			updateActiveModule(nextModule);
		}
	};

	const checkIfCompleted = async () => {
		await getCourseDetails();
	};

	const handleCompleteLesson = async () => {
		setError(false);
		setLoading(true);

		const courseService = new CourseService();
		const response = await courseService.mark_lesson_as_complete(lesson.id);

		if (response.success) {
			setSuccess(true);
			lesson.completed = true;
			setNextLesson();
		} else {
			setTimeout(function () {
				setError(false);
			}, 5000);
			setError(true);
		}
		await checkIfCompleted();
		setLoading(false);
	};

	const { getLanguageStrings } = useLanguageContext();

	const transition = {
		repeat: Infinity,
		duration: 3,
	};

	return (
		<Box display='flex'>
			{error ? (
				<Button variant='link' color='primary' disabled>
					<motion.div
						animate={{
							opacity: [0, 1],
						}}
						transition={transition}>
						Error{" "}
					</motion.div>
				</Button>
			) : (
				<>
					{loading ? (
						<Loading variant='simple' size='20px' />
					) : (
						<>
							{!lesson.isQuiz && (
								<>
									{!lesson.completed && !success ? (
										<Button variant='link' color='primary' onClick={handleCompleteLesson}>
											<Box display='flex' alignItems='center'>
												<IconWrapper size='20px' primaryColor filled={false} marginRight='5px' icon={<IoIosCheckmarkCircleOutline size='30px' />} />
												{getLanguageStrings().courses.course.markAsDoneBtn}
											</Box>
										</Button>
									) : (
										<Button variant='link' color='primary' disabled>
											<Box display='flex' alignItems='center'>
												<IconWrapper size='20px' primaryColor filled={false} marginRight='5px' icon={<IoIosCheckmarkCircle size='30px' />} />
												{getLanguageStrings().courses.course.lessonDoneLabel}
											</Box>
										</Button>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</Box>
	);
};

CompleteLessonButtonWrapper.defaultProps = {};

export { CompleteLessonButtonWrapper as CompleteLessonButton };
