import React, { ReactNode, useEffect, useState } from "react";

import { Header } from "../..";
import { Sidebar } from "../Sidebar";
import { SearchBar } from "../../features/SearchBar/SearchBar";
import { PageContainer, MainContent, FeedBackButton } from "./PageStyled";
import { PageTitle } from "../../elements";
// import { FaBullhorn } from "react-icons/fa";
import { isMobile } from "../../../isMobile";
// import { PlusExclusiveBar } from "../../elements/PlusExclusiveBar";
// import { useUserContext } from "../../../context/user/AuthContext";

import logoNMA from "../../../assets/images/logo-b42.jpg";
import { TContentType } from "../../../types/helpers";
import { useLanguageContext } from "../../../context/language/languageContext";
import { LanguageSelector } from '../..'

export interface Props {
	children?: ReactNode;
	showBreadcrumb?: boolean;
	showUserAvatar?: boolean;
	showSchoolLogo?: boolean;
	pageTitle?: string;
	pageTitleHighlightText?: number | string;
	pageTitleChildren?: ReactNode;
	searchHelpText?: string;
	showSearch?: boolean;
	showMenu?: boolean;
	plusExclusive?: boolean;
	hideBanner?: boolean;
	searchContentType?: TContentType;
}

const mockData = {
	title: "Centro Integrado de Aprendizagem em Autismo",
	subtitle: "Ambiente de estudos e comunidade oficial da Academia do Autismo",
	username: "Vinícius Loureiro",
	userImageUrl: "https://gabrielbaptista.com.br/joinlearn-prototipo/static-assets/images/users/vinicius.png",
	notificationCount: 15,
	logoUrl: "https://meeting.academiadoautismo.com.br/images/nma-bd-jl.jpg",
};

const PageWrapper: React.FC<Props> = ({
	children,
	showBreadcrumb,
	pageTitle,
	pageTitleHighlightText,
	pageTitleChildren,
	showUserAvatar,
	showSchoolLogo,
	searchHelpText,
	showSearch,
	showMenu,
	searchContentType,
	...props
}: Props) => {
	const { getLanguageStrings } = useLanguageContext()!;
	// 	const { isPlus, alunoABA } = useUserContext();
	// const [showFeedbackButton, setShowfeedbackButton] = useState(true);
	// useEffect(() => {
	// 	if (localStorage.getItem("ciaautismo:feedback")) {
	// 		setShowfeedbackButton(false);
	// 	}
	// }, []);
	// const setFeedbackGiven = () => {
	// 	setShowfeedbackButton(false);
	// 	localStorage.setItem("ciaautismo:feedback", `true`);
	// };
	return (
		<PageContainer {...props}>
			{showMenu && <Sidebar logoUrl={logoNMA} />}
			<MainContent className={showMenu ? "content" : "content noMenu"}>
				<Header
					background='primary'
					title={getLanguageStrings().appName}
					subTitle={getLanguageStrings().subtitle}
					username={mockData.username}
					userImageUrl={mockData.userImageUrl}
					notificationCount={mockData.notificationCount}
					logoUrl={logoNMA}
					showUserAvatar={showUserAvatar}
					showSchoolLogo={showSchoolLogo}
				/>
				{/* {props.plusExclusive && <PlusExclusiveBar />} */}
				{(pageTitle || pageTitleChildren || pageTitleHighlightText) && (
					<PageTitle showBreadcrumb={false} title={pageTitle} highlightText={pageTitleHighlightText}>
						{pageTitleChildren}
					</PageTitle>
				)}
				{showSearch && <SearchBar contentType={searchContentType ? searchContentType : 'all'} helpText={searchHelpText} />}
				{children}
				<footer>
					<span>
						{new Date().getFullYear()} - Agência B42®{!isMobile.any() && <> -&nbsp;</>}
					</span>
					<a href='#'>{getLanguageStrings().footer.terms.text}</a>
					<LanguageSelector languages={['enus', 'ptbr']} />
				</footer>
			</MainContent>
			{/* {showMenu && showFeedbackButton && (
				<FeedBackButton onClick={setFeedbackGiven}>
					<div className='feedbackTip'>Conta pra gente que tipo de conteúdos você gostaria de ver aqui no CIA Autismo.</div>
					<a href='https://forms.gle/aFKZQb21Ze1sL7TL8' target='_blank' rel='nofollow'>
						O que você quer ver no CIA Autismo?&nbsp;
						<FaBullhorn size={isMobile.any() ? 14 : 20} />
					</a>
				</FeedBackButton>
			)} */}
		</PageContainer>
	);
};
PageWrapper.defaultProps = {
	showBreadcrumb: true,
	showUserAvatar: true,
	showSchoolLogo: true,
	showSearch: false,
	showMenu: true,
};

export { PageWrapper as PageContainer };
