import styled from "styled-components";
import { lighten } from "polished";
import { Props } from "./index";

const FavoritesContainer = styled.div<Props>`
	display: flex;
	flex-wrap: wrap;
`;

const ButtonWrapper = styled.div<Props>`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	background: #fff;
	border-radius: 6px;
	border: 1px solid #f2f2f2;
	width: 100%;
	margin: 15px 20px 0 15px;
	transition: 0.3s;
	a {
		display: block;
		padding: 12px 0;
		width: 100%;
		text-align: center;
		font-size: 0.7rem;
		text-transform: uppercase;
	}
	&:hover {
		background: ${(props) => lighten(0.43, props.theme.color.primary)};
	}
`;

export { FavoritesContainer, ButtonWrapper };
