import React from "react";
import { IconsRoundedGroup, IconTextWrapper, CertificateIcon, CourseInProgressIcon, LessonsCountIcon, CompletedCourseIcon } from "../../../Icons";
import { FiClock } from "react-icons/fi";
import { useLanguageContext } from "../../../../../context/language/languageContext";
import Theme from '../../../../../styles/theme';

export interface Props {
	certificate: boolean;
	status: string | boolean | "notStarted";
	releasedLessons: number;
}

const CourseCardIconsToolbarWrapper: React.FC<Props> = ({ certificate, status, releasedLessons, ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext();
	return (
		<IconsRoundedGroup>
			<div>{certificate && <IconTextWrapper text={getLanguageStrings().courses.card.hasCertificate} icon={<CertificateIcon color={Theme.color.secondary} />} />}</div>
			<div>
				{status === "notStarted" ? (
					<IconTextWrapper text={getLanguageStrings().courses.card.status.notStarted} icon={<FiClock size={22} color={Theme.color.secondary} />} />
				) : (
					<>{status === false ? <IconTextWrapper text={getLanguageStrings().courses.card.status.inProgress} icon={<CourseInProgressIcon color={Theme.color.secondary} />} /> : <IconTextWrapper text={getLanguageStrings().courses.card.status.finished} icon={<CompletedCourseIcon color={Theme.color.secondary} />} />}</>
				)}
			</div>
			<div>
				<IconTextWrapper text={getLanguageStrings().courses.card.availableClasses} icon={<LessonsCountIcon count={releasedLessons} />} />
			</div>
		</IconsRoundedGroup>
	)
};

export { CourseCardIconsToolbarWrapper as CourseCardIconsToolbar };
