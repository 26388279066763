import React from "react";
import styled from "styled-components";

const LessonsCountIconContainer = styled.div<Props>`
	font-weight: bold;
	font-size: ${(props) => props.size};
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	border-color: ${(props) => props.theme.color.primary};
	background-color: ${(props) => props.theme.color.secondary};
	color: #fff;
	padding: 4px 10px;
	border-radius: 5px 5px 0px 5px;
`;

export interface Props extends React.HTMLAttributes<HTMLElement> {
	size?: string;
	count: number;
}

const LessonsCountIconWrapper: React.FC<Props> = ({ count, size, ...props }: Props) => (
	<LessonsCountIconContainer size={size} count={count}>
		{count}
	</LessonsCountIconContainer>
);

LessonsCountIconWrapper.defaultProps = {
	size: "0.875rem",
};

export { LessonsCountIconWrapper as LessonsCountIcon };
