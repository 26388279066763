import styled, { css } from "styled-components";
import media from "../../styles/media";

interface ThinBannerProps {
	imgUrl: string;
	mobileImgUrl: string;
}

const mobileThinBanner = css<ThinBannerProps>`
	background-image: ${(props) => props.mobileImgUrl};
`;

const HomeContainer = styled.div`
	display: flex;
`;

const FeaturedContentContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 15px;
	${media.lessThan("medium")`
		grid-template-columns: 1fr;
    `}
`;
const ThinBanner = styled.div<ThinBannerProps>`
	width: 100%;
	height: 150px;
	background-image: url(${(props) => props.imgUrl});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 5px;
	margin-bottom: 35px;
	${media.lessThan("medium")`
		mobileThinBanner
	`};
`;

const WeeklyContentWrapper = styled.section`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const VerticalFeatureBanner = styled.div`
	width: 21.7%;
	${media.lessThan("medium")`
		width: 100%;
		margin: 0 0 15px;
    `}
	img {
		width: 100%;
	}
`;

const VideoWrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
	width: 53%;
	position: relative;
	height: 100%;
	${media.lessThan("medium")`
		width: 100%;
		margin: 0 0 15px;
    `}
	> div {
		display: block;
		content: "";
		padding-top: 56.25%;
		width: 100% !important;
		height: auto !important;
		position: relative;
		div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
`;

const ErrorWrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
	width: 55%;
	position: relative;
	height: 100%;
	${media.lessThan("medium")`
		width: 100%;
		margin: 0 0 15px;
	`}
`;

const BannerCursoFree = styled.div`
	width: 100%;
	a,
	img {
		display: block;
	}
	img {
		width: 100%;
	}
	img.desk {
		display: block;
		${media.lessThan("medium")`
			display: none;
		`}
	}
	img.mob {
		display: none;
		${media.lessThan("medium")`
			display: block;
		`}
	}
`;

const BannerButtonForm = styled.div`
	margin-top: 16px;
	button {
		width: 100%;
		cursor: pointer;
		img {
			width: 100%;
		}
	}
`;

export {
	HomeContainer, //
	FeaturedContentContainer,
	ThinBanner,
	mobileThinBanner,
	VideoWrapper,
	VerticalFeatureBanner,
	WeeklyContentWrapper,
	ErrorWrapper,
	BannerCursoFree,
	BannerButtonForm,
};
