import styled, { css } from "styled-components";
import { lighten } from 'polished';

interface Props {
	open: boolean;
}

const SidebarContainer = styled.div<Props>`
	display: flex;
	ul {
		overflow: hidden;
	}
	.handleDrawer {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1300;
		color: #ffffff !important;
		color: ${(props) => props.theme.color.primary};
		top: 18px;
		left: 7px;
		&.isPlus {
			color: ${(props) => props.theme.color.primary};
		}
	}
	.MuiListItemText-root {
		margin-left: 7px;
	}
	.MuiListItemText-primary {
		font-weight: 700;
		color: white;
		font-size: 13px;
		text-transform: uppercase;
		//margin: 0 0 0 3px;
	}
	.MuiListItem-root {
		&:first-child {
			margin-bottom: 10px !important;
		}
		&.MuiListItem-gutters {
			margin: 0px;
			padding: 8px 10px;
		}
		.MuiListItemIcon-root {
			min-width: 58px;
			> div {
				background: ${(props) => lighten(0.2, props.theme.color.primary)};
				//box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.05);
				box-shadow: 0px 3px 6px #8cb1de55;
				width: 50px;
				height: 50px;
					border: 2px solid ${(props) => props.theme.color.secondary};
				> svg path {
					fill: white
				}
			}
		}
		&:hover {
			background-color: transparent;
			.MuiListItemIcon-root {
				> div {
					background: white;
					border: 2px solid ${(props) => props.theme.color.secondary};
					> svg path {
						fill: ${(props) => props.theme.color.secondary} !important;
					}
				}
			}
		}
		.active {
			.MuiListItemText-primary {
				color: white;
			}
			.MuiListItemIcon-root {
				> div {
					background: white;
					border: 2px solid  ${(props) => props.theme.color.secondary};
					> svg path {
						fill: ${(props) => props.theme.color.secondary} !important;
					}
				}
			}
		}
	}
`;

const MenuHeight = styled.div`
	height: ${(props) => props.theme.headerHeight};
`;
export { SidebarContainer, MenuHeight };
