import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

const SquareCardContainer = styled(Link)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 5px;
	text-decoration: none;
	transition: 0.3s ease;
	position: relative;
	top: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	&:hover {
		top: -10px;
	}
`;

const CardImage = styled.div`
	width: 100%;
	position: relative;
	&:before {
		content: "";
		display: block;
		padding-top: 56.9%;
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		&.loadingGif {
			z-index: 1;
			object-fit: none;
			width: 90px;
			height: 90px;
		}
	}
`;

const CardBottom = styled.div`
	background-color: #fff;
	padding: 13px 22px;
	border: 1px solid #efefef;
	border-top: none;
	flex: 1;
	small {
		color: #828282;
	}
`;

const CardTitle = styled.span`
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.2em;
	display: block;
	color: ${(props) => darken(0.01, props.theme.color.primary)};
`;

const CardMeta = styled.small`
	display: block;
	font-size: 0.7rem;
	line-height: 1.2em;
	opacity: 0.4;
`;

const CardDescription = styled.p`
	font-size: 0.9rem;
	line-height: 1.2em;
	margin: 27px 0 15px;
	color: #828282;
`;

const CardTagsWrapper = styled.div`
	/* background-color: #fff; */
	padding: 8px 0px;
	display: flex;
	//flex-wrap: wrap;
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: #eee #fff;

	&::-webkit-scrollbar {
		//display: none;
		height: 10px;
		padding: 1px;
	}
	&::-webkit-scrollbar-thumb {
		background: #eee;
		opacity: 0.4;
		border-radius: 5px;
		border: 3px solid #fff;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

const Tag = styled(Link)`
	text-decoration: none;
	white-space: nowrap;
	border-radius: 100px;
	font-weight: 400;
	height: 35px;
	padding: 0 20px;
	line-height: 30px;
	font-size: 0.75rem;
	color: #222b37;
	cursor: pointer;
	box-sizing: border-box;
	background-color: #f2f2f2;
	border: 1px solid #e0e0e0;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	margin: 5px 5px 0 0;

	&:focus {
		border: 1px solid #e0e0e0;
	}
	&:hover {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
	&.active {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
`;

export { SquareCardContainer, CardImage, CardBottom, CardTitle, CardMeta, CardDescription, CardTagsWrapper, Tag };
