import React, { ReactNode, useState, useRef, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { IoIosHeart } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "../../../../isMobile";
import { PlayListIcon, IconWrapper, IconsGroup } from "../../../elements/Icons";
import { Button, StudentsAvatarGroup } from "../../../elements";
import { Typography } from "../../../Typography";

import { ViewClassHeaderContainer } from "./ViewClassHeaderStyled";
import { Student, ModuleClass } from "../../../../types/course";
import { AddItemToCustomPlaylistCard } from "../../CustomPlaylist/AddItemToCustomPlaylistCard";

import { customPlaylistNamesData } from "../../../../mockData/customPlaylistDataMock";
import { CompleteLessonButton } from "..";
import { useLanguageContext } from "../../../../context/language/languageContext";

export interface Props {
	name: string;
	moduleName: string;
	activeLesson?: ModuleClass | undefined;
	nextLesson?: ModuleClass | undefined;
	releaseDate?: string;
	students?: Student[];
	studentsCount?: number;
	children?: ReactNode;
}

const ViewClassHeaderWrapper: React.FC<Props> = ({ name, moduleName, releaseDate, students, studentsCount, nextLesson, children, activeLesson, ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext();
	const [showAddItemToPlaylist, setShowAddItemToPlaylist] = useState(false);
	const handleToogleAddItemToPlaylist = () => {
		setShowAddItemToPlaylist(!showAddItemToPlaylist);
	};
	const addItemToPlaylistRef = useRef<HTMLDivElement>(null);
	const handleClickOutside = (e: MouseEvent) => {
		const node = addItemToPlaylistRef.current;
		if (node?.contains(e.target as Node)) {
			// inside click
			return;
		}
		// outside click
		setShowAddItemToPlaylist(false);
	};

	useEffect(() => {
		if (showAddItemToPlaylist) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showAddItemToPlaylist]);
	return (
		<ViewClassHeaderContainer>
			<Box position='relative' display='flex' justifyContent='space-between' marginTop={{ xs: "15px;", md: "0" }} alignItems={{ xs: "flex-start", sm: "flex-start", md: "flex-end" }}>
				<Box display='flex' flexDirection='column' maxWidth='70%'>
					<Typography variant='uppertitle'>{moduleName}</Typography>
					{releaseDate && (
						<span className='release'>
							<Typography variant='subtitle'>{getLanguageStrings().courses.course.released} {releaseDate}</Typography>
						</span>
					)}
					<Typography variant='title'>{name}</Typography>
				</Box>
				{students ? (
					<StudentsAvatarGroup avatarSize='50px' fontSize='18px' students={students} studentsCount={studentsCount} />
				) : (
					<Box right={0} top={0} position={{ xs: "absolute", md: "relative" }}>
						{activeLesson && <CompleteLessonButton lesson={activeLesson} />}
						<IconsGroup>
							<Box position='relative' zIndex='900'>
								<div ref={addItemToPlaylistRef}>
									{/* <PayListIcon onClick={() => handleToogleAddItemToPlaylist()} tooltipText='Adicionar a trilha' size={isMobile.any() ? "30px" : "40px"} iconSize={isMobile.any() ? "15px" : "20px"} /> */}
									<AnimatePresence initial={false}>
										{showAddItemToPlaylist && (
											<motion.section
												initial='collapsed'
												animate='open'
												variants={{
													open: { opacity: 1 },
													collapsed: { opacity: 0 },
												}}
												exit='collapsed'
												transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
												<AddItemToCustomPlaylistCard playlists={customPlaylistNamesData()} />
											</motion.section>
										)}
									</AnimatePresence>
								</div>
							</Box>
							{/* <IconWrapper tooltipText="Adicionar aos favoritos" icon={<IoIosHeart color="#FFF" size={isMobile.any() ? '18px' : '25px'} />} backgroundColor="#E0E0E0" size={isMobile.any() ? '30px' : '40px'} rounded /> */}
						</IconsGroup>
					</Box>
				)}
			</Box>
		</ViewClassHeaderContainer>
	);
};

export { ViewClassHeaderWrapper as ViewClassHeader };
