import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { PageContainer, GreyContainer, InnerContentContainer, ContentLoadingError } from "../../components";
import { Button, CourseCardLoading, SquareCard } from "../../components/elements";

import { ArticlesContainer, ViewMoreWrapper, CategoriesContainer, FilterTitle } from "./ArticlesStyled";
import { Tag } from "../../components/elements/SquareCard/SquareCardStyled";
import ArticlesService from "../../services/articles.service";
import ArticleCategoriesService from "../../services/articlesCategories.service";
import loadingGif from "../../assets/images/loading.gif";
import { useParams } from "react-router-dom";

import { IArticle, IArticleCategory } from "../../types/articles"
// import { HomePageFeatureSlider } from "../../components/features/HomePageFeatureSlider";
import { useLanguageContext } from "../../context/language/languageContext";

export interface Props {
	background?: string;
}

const Articles: React.FC<Props> = ({ children, ...props }) => {
	const { getLanguageStrings } = useLanguageContext();
	const [lastPage, setLastPage] = useState(false);
	const [articlesNextPage, setArticlesNextPage] = useState(1);
	const [articlesCount, setArticlesCount] = useState(0);
	const [articles, setArticles] = useState<IArticle[]>([]);
	const [viewMoreDisabled, setViewMoreDisabled] = useState(false);
	const [loadingArticles, setLoadingArticles] = useState(true);
	const [articleCategories, setArticleCategories] = useState<IArticleCategory[]>([]);
	const { category } = useParams<{ category: string }>();
	// const history = useHistory();

	const getArticles = useCallback(async () => {
		const articlesService = new ArticlesService();
		const response = await articlesService.list();

		if (response.next) {
			setArticlesNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
			setLastPage(false);
		} else {
			setViewMoreDisabled(true);
			setLastPage(true);
		}

		setArticles(response.results);
		setArticlesCount(response.count);
		setLoadingArticles(false);
	}, []);

	const getArticlesByCategory = useCallback(async (category) => {
		const articlesService = new ArticlesService();
		const response = await articlesService.get_by_category_slug(category);

		if (response.next) {
			setArticlesNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
			setLastPage(false);
		} else {
			setViewMoreDisabled(true);
			setLastPage(true);
		}
		setArticles(response.results);
		setArticlesCount(response.count);
		setLoadingArticles(false);
	}, []);

	const loadMoreArticles = useCallback(async () => {
		setViewMoreDisabled(true);
		const articlesService = new ArticlesService(articlesNextPage);
		const response = await articlesService.list();

		setArticles([...articles, ...response.results]);

		if (response.next) {
			setArticlesNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
		} else {
			setLastPage(true);
			setViewMoreDisabled(true);
		}
	}, [articles, articlesNextPage]);

	const trucateString = useCallback((txt: string | undefined, charCount: number) => {
		if (txt && txt.length > charCount) {
			return `${txt.substring(0, charCount)}[...]`;
		}
		return txt;
	}, []);

	const normalizeDate = useCallback((date: string) => {
		const dateToNormalize = new Date(date);

		const parsedDate = Date.parse(date);

		if (isNaN(parsedDate)) {
			if (date === undefined) return "Sem data";
			const arrayOfDateComponents = date.split("/");
			return `
      ${arrayOfDateComponents[0]}/
      ${arrayOfDateComponents[1]}/
      ${arrayOfDateComponents[2].split(" ")[0]}`;
		}

		return dateToNormalize.toLocaleDateString("pt-BR");
	}, []);

	const getCategories = useCallback(async () => {
		const articleCategoriesService = new ArticleCategoriesService();
		const response = await articleCategoriesService.list();
		// console.log("ARTICLE CATEGORIES", response);
		setArticleCategories(response);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (category) {
			getArticlesByCategory(category);
		} else {
			getArticles();
		}
		getCategories();
		// eslint-disable-next-line
	}, [category]);

	return (
		<PageContainer
			pageTitle={category ? `${getLanguageStrings().titles.articles} - "${category}"` : getLanguageStrings().titles.articles}
			showSearch={false}
			showBreadcrumb
			pageTitleHighlightText={articlesCount}
			plusExclusive
			searchHelpText='Buscar artigos'>
			<FilterTitle>{getLanguageStrings().categoriesText}</FilterTitle>
			<CategoriesContainer>
				{articleCategories ? (
					<>
						{articleCategories.map((cat, index) => (
							<React.Fragment key={index}>
								{cat.slug === category ? (
									<Tag to={`/artigos`} title='Remover Filtro' className='active'>
										{cat.descricao}
									</Tag>
								) : (
									<Tag to={`/artigos/categoria/${cat.slug}`} title={cat.descricao}>
										{cat.descricao}
									</Tag>
								)}
							</React.Fragment>
						))}
					</>
				) : (
					"Ocorreu um erro ao carregar as categorias"
				)}
			</CategoriesContainer>
			<GreyContainer>
				<InnerContentContainer>
					<ArticlesContainer {...props}>

						{/* <HomePageFeatureSlider location="ARTIGOS" /> */}
						<Box
							width='100%'
							display='grid'
							gridTemplateColumns={{
								xs: "100%",
								sm: "100%",
								md: "24% 24% 24% 24%",
							}}
							gridRowGap='15px'
							gridColumnGap='1%'>
							{loadingArticles ? (
								<>
									<CourseCardLoading /> <CourseCardLoading />
									<CourseCardLoading /> <CourseCardLoading />
								</>
							) : (
								<>
									{articles ? (
										<>
											{articles.map((article, index) => (
												<SquareCard
													key={index}
													to={`/artigo/${article.slug}`}
													imageurl={article.capa ? article.capa.imagem_thumbnail : "https://ajuda.nossomundoazul.com.br/other-assets/artigos-fallback.jpg"}
													title={article.titulo}
													description={trucateString(article.resumo, 100)}
													meta={`${normalizeDate(article.data_publicacao)}`}
													tags={article.tags}
													categories={article.categorias}></SquareCard>
											))}
										</>
									) : (
										<ContentLoadingError refresh={getArticles} loadedResourceErrorMsg='a lista de Artigos' />
									)}
								</>
							)}
						</Box>
						{articles && (
							<>
								{!loadingArticles && (
									<ViewMoreWrapper>
										<Button
											variant='contained'
											color='primary'
											disabled={viewMoreDisabled}
											onClick={() => {
												loadMoreArticles();
											}}>
											{getLanguageStrings().loadMore.articles}
										</Button>
										{viewMoreDisabled && !lastPage ? <img className='loadingGif' src={loadingGif} alt='loading' /> : ""}
									</ViewMoreWrapper>
								)}
							</>
						)}
					</ArticlesContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Articles };
