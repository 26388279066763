import React, { ReactNode } from "react";
import { Typography } from "../..";
import { useLanguageContext } from "../../../context/language/languageContext";
import { WhoCanSeeTagContainer } from "./WhoCanSeeTagStyled";

export interface Props {
	background?: string;
	children?: ReactNode;
	value?: "all" | "owner";
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const WhoCanSeeTagWrapper: React.FC<Props> = ({ children, value, onChange, ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext();
	return (
		<WhoCanSeeTagContainer {...props}>
			<Typography variant='text' size='0.75rem' lineHeight='1' primary>
				{getLanguageStrings().profile.availabilityToggle.title}
			</Typography>

			<div className='selectWrapper'>
				<select name='whoCanSee' onChange={onChange} value={value}>
					<option value='all'>{getLanguageStrings().profile.availabilityToggle.public}</option>
					<option value='owner'>{getLanguageStrings().profile.availabilityToggle.private}</option>
				</select>
			</div>
		</WhoCanSeeTagContainer>
	)
};

WhoCanSeeTagWrapper.defaultProps = {
	background: "#FFF",
};

export { WhoCanSeeTagWrapper as WhoCanSeeTag };
