import React, { ChangeEvent, useState } from "react";
import clsx from "clsx";
import { FaUserAlt } from "react-icons/fa";
import { IoCameraOutline, IoPeopleSharp } from "react-icons/io5";
import { IconWrapper } from "../Icons";
import { CircularAvatarPhotoContainer } from "./CircularAvatarPhotoStyled";
import { ModalBase } from "../../features/Producer_bkp/ModalBase";
import { Box } from "@material-ui/core";
import StudentProfileService from "../../../services/student.profile.service";
import { useUserContext } from "../../../context/user/AuthContext";
import { useLanguageContext } from "../../../context/language/languageContext";

export interface Props {
	avatarImg?: string;
	avatarName?: string;
	size?: string;
	isMonitor?: boolean;
	isTutor?: boolean;
	isProfessor?: boolean;
	/** Cor 'primary' | 'default' | 'white' | undefined  */
	borderColor?: "primary" | "default" | "white" | undefined;
	/** Cor 'single' | 'double' | 'triple' */
	borderType?: "single" | "double" | "double-white" | "triple";
	darken?: boolean;
	lighten?: boolean;
	innerBorderSize?: string;
	outerBorderSize?: string;
	isUserProfile?: boolean;
}

const CircularAvatarPhotoWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const { getLanguageStrings } = useLanguageContext();
	const { userData } = useUserContext();
	const [open, setOpen] = useState(false);
	const [avatar, setAvatar] = useState<any>();
	const [student, setStudent] = useState<any>();
	const [loading, setLoading] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const loadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
		const formData = new FormData();
		if (e.target.files) {
			formData.append("nome", userData.nome_aluno);
			formData.append("foto", e.target.files[0]);
			setAvatar(formData);
		}
	};

	const updateAvatar = async () => {
		setLoading(true);
		const studentService = new StudentProfileService();
		await studentService.updateAvatar(avatar);
		await setNewStudentImage();
		setLoading(false);
	};

	const setNewStudentImage = async () => {
		const studentService = new StudentProfileService();
		const response = await studentService.list();
		//console.log("STUDENT RESPONSE", response);

		localStorage.setItem("userImg", response.foto);
		setStudent(response);
		handleClose();
	};

	return (
		<CircularAvatarPhotoContainer borderColor={props.borderColor} {...props}>
			{props.isUserProfile ? (
				<>
					<div
						className={clsx("roundContainer", { isMonitor: props.isMonitor || props.isTutor || props.isProfessor }, { isUserProfile: props.isUserProfile })}
						title='Editar Foto'
						onClick={handleClickOpen}>
						{student?.foto ? (
							<>
								<img src={student.foto} alt={student.nome} />
							</>
						) : (
							<>
								{props.avatarImg ? (
									<img src={props.avatarImg} alt={props.avatarName} />
								) : (
									<div className='user-place-holder'>
										{" "}
										<IconWrapper filled={false} primaryColor size='35px' icon={<FaUserAlt size={35} />} />
									</div>
								)}
							</>
						)}
					</div>
					{props.isTutor ? <span>Tutor</span> : props.isMonitor ? <span>Mentor</span> : props.isProfessor ? <span>Professor</span> : ""}
					<button onClick={handleClickOpen} className='cameraIcon'>
						<IoCameraOutline size={20} color='#FFF' />
					</button>

					<ModalBase loading={loading} open={open} title={getLanguageStrings().profile.updateAvatarModal.title} handleClose={handleClose} saveAction={updateAvatar}>
						<Box display='grid' gridTemplateColumns='1fr' gridTemplateRows='2fr' gridRowGap='20px' gridColumnGap='20px'>
							<input type='file' name='avatar' id='avatar' onChange={(e) => loadPhoto(e)} />
						</Box>
					</ModalBase>
				</>
			) : (
				<>
					<div className={clsx("roundContainer", { isMonitor: props.isMonitor || props.isTutor || props.isProfessor })}>
						{props.avatarImg ? (
							<img src={props.avatarImg} alt={props.avatarName} />
						) : (
							<div className='user-place-holder'>
								{" "}
								<IconWrapper filled={false} primaryColor size='35px' icon={<FaUserAlt size={35} />} />
							</div>
						)}
					</div>
					{props.isTutor ? <span>Tutor</span> : props.isMonitor ? <span>Mentor</span> : props.isProfessor ? <span>Professor</span> : ""}
				</>
			)}
		</CircularAvatarPhotoContainer>
	);
};

CircularAvatarPhotoWrapper.defaultProps = {
	size: "45px",
	innerBorderSize: "5px",
	outerBorderSize: "10px",
	borderColor: undefined,
};

export { CircularAvatarPhotoWrapper as CircularAvatarPhoto };
