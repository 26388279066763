import styled from "styled-components";
import media from "../../../styles/media";
import { Props } from "./index";
import { CircularAvatarPhotoContainer } from "../../elements/CircularAvatarPhoto/CircularAvatarPhotoStyled";

const HeaderContainer = styled.div<Props>`
	/*  */
		background: ${(props) => props.theme.color.primary};
		border-bottom: 1px solid ${(props) => props.theme.color.primary};
	height: ${(props) => props.theme.headerHeight};
	width: 100%;
	min-width: 1000px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px 0 5px;
	color: #fff;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1201;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	&.isPlus {
		background: ${(props) => props.theme.color.primary};
		border-bottom: 1px solid ${(props) => props.theme.color.primary};
	}
	${media.lessThan("medium")`
		height:  60px;
		padding: 0 0 0 30px;
		min-width: 0px;
	`}
	.schoolInfo {
		display: flex;
		align-items: center;
		max-width: 700px;
		overflow: hidden;
		${media.lessThan("medium")`
      		flex: 1;
    	`}
		.schoolLogo {
			${media.lessThan("medium")`
				display: none;
			`}
		}
	}
	h1 {
		font-size: 1.25rem;
		margin: 0 auto 0 5px;
		font-weight: normal;
		color: #FFF;
		&.logoNma {
			a {
				${media.lessThan("medium")`
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
				`};
			}
			img {
				max-height: 48px;
				${media.lessThan("medium")`
					height: 30px;
					margin-right: -35px;
				`};
			}
		}
		${media.lessThan("medium")`
			font-size: .9rem;
			margin: 0 auto 0 0;
			flex: 1;
			text-align: center;
			/* max-width: calc(100% - 50px); */
		`}
		&.hideLogo {
			${media.lessThan("medium")`
				visibility: hidden;
			`}
		}
		span {
			color: #f2c94c;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 0.9rem;
		}
		small {
			display: block;
			font-size: 0.75rem;
			opacity: 0.6;
			${media.lessThan("medium")`
				display: none;
			`}
		}
	}
	&.isPlus {
		h1 {
			color: #fff;
		}
	}
	.studentInfo {
		width: 30%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		${media.lessThan("medium")`
			width: auto;
			margin-right: 10px;
			padding-top: 4px;
		`}
		.nameLogout {
			text-align: right;
		}
		.logoutButton {
			outline: 0;
			color: #FFF;
			background: none;
			font-size: 0.75rem;
			margin-top: 4px;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}

			${media.lessThan("medium")`
				display: none;
			`}
		}
		.nomeAluno {
			color: #FFF;
			font-size: 0.75rem;
			display: block;
			${media.lessThan("medium")`
				display: none;
			`}
			.plus {
				color: #f2c94c;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 0.8rem;
				margin-bottom: -4px;
			}
		}
		${CircularAvatarPhotoContainer} {
			position: relative;
			z-index: 1;
			margin: 0 0 0 10px;
			${media.lessThan("medium")`
				margin: 0;
			`}
		}
		.notificationBell {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			border: 2px solid ${(props) => props.theme.color.secondary};
			border-radius: 50%;
			margin-left: -15px;
			position: relative;
			z-index: 2;
			background: ${(props) => props.theme.color.primary};
			${media.lessThan("medium")`
				width: 25px;
				height: 25px;
				margin-left: -10px;
				svg {
				width: 15px;
				height: auto;
				}
			`}
		}
		.notificationCount {
			margin-left: 5px;
			color: ${(props) => props.theme.color.primary};
			font-weight: bold;
			font-size: 0.75rem;
			${media.lessThan("medium")`
				visibility: hidden;
				display: none;
			`}
		}
	}
	&.isPlus {
		.notificationCount,
		.nomeAluno,
		.logoutButton {
			color: #fff;
		}
	}
`;
export { HeaderContainer };
