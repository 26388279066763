import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { CircularAvatarPhoto } from "../../components/elements";

import { useUserContext } from "../../context/user/AuthContext";

import { LoginContainer } from "./LoginStyled";

import bgLogin from "../../assets/images/bglogin.jpg";
import { useLanguageContext } from "../../context/language/languageContext";

export interface Props {
	backgroundColor?: string;
	backgroundURL?: string;
}

interface State {
	email: string;
	password: string;
	showPassword: boolean;
}

const Login: React.FC<Props> = ({ ...props }) => {
	const { handleLogin, tenantData, userData } = useUserContext();
	const [userImg, setuserImg] = useState("");
	const [values, setValues] = useState({
		email: "",
		password: "",
		showPassword: false,
	});
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const history = useHistory();

	const { getLanguageStrings } = useLanguageContext()!

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!values.email || !values.password) {
			setError(true);
			setErrorMsg("Preencha e-mail e senha para continuar!");
		} else {
			const response = await handleLogin(values.email.toLowerCase(), values.password);
			//console.log("response from login.tsx", response);

			if ("success" in response) {
				history.push("/redirection");
			} else {
				setError(true);
				setErrorMsg(JSON.stringify(response));
			}
		}
	};

	useEffect(() => {
		setuserImg(localStorage.getItem("userImg") || "");
		if (userData) {
			history.push("/redirection");
		}
	}, [userData, history]);

	return (
		<LoginContainer {...props} backgroundURL={tenantData?.configs.imagem_login ? tenantData?.configs.imagem_login : bgLogin}>
			<div className='innerLoginWrapper'>
				<CircularAvatarPhoto avatarImg={userImg} avatarName={tenantData?.name} size='120px' />
				<h1>{getLanguageStrings().appName}</h1>
				<p className='highlight'>{tenantData?.configs.texto_login}</p>

				<form action='#' onSubmit={handleSignIn}>
					<div>{error}</div>
					<FormControl error={error} fullWidth>
						<InputLabel htmlFor='email'>Usuário</InputLabel>
						<Input
							fullWidth
							id='standard-adornment-password'
							type='text'
							value={values.email.toLowerCase()}
							onChange={handleChange("email")}
							endAdornment={
								<InputAdornment position='end'>
									<AccountCircle />
								</InputAdornment>
							}
						/>
					</FormControl>

					<FormControl error={error} fullWidth>
						<InputLabel htmlFor='standard-adornment-password'>Senha</InputLabel>
						<Input
							fullWidth
							id='standard-adornment-password'
							type={values.showPassword ? "text" : "password"}
							value={values.password}
							onChange={handleChange("password")}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton aria-label='mostrar senha' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
										{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{errorMsg && <FormHelperText id='component-error-text'>{getLanguageStrings().wrongCredentials}</FormHelperText>}
					</FormControl>
					<Link style={{ textDecoration: "none" }} to='/esqueci-senha'>
						{getLanguageStrings().login.forgot}
					</Link>
					<div className='buttonWrapper'>
						<button type='submit'>Entrar</button>
					</div>
				</form>
			</div>
		</LoginContainer>
	);
};

export { Login };
