import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Quiz, QuizAlternative, QuizAnswer, QuizQuestion, QuizAnswerResponse, Attempt, QuizAnswerCorrection } from "../../types/course";
import { Button } from "../elements";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import {
	QuizContainerContainer, //
	QuestionContainer,
	QuestionTitle,
	QuestionOptionsContainer,
	RadioWrapper,
	ButtonWrapper,
	ErrorText,
	CorrectionTitle,
	QuestionCommentary,
	VideoWrapper,
} from "./QuizContainerStyled";
// import { Typography } from "../Typography";
import { Box } from "@material-ui/core";

import CourseService from "../../services/course.service";
//TODO: tirar esse prop "quizObject" e preencher essa variável com  o resultado do retorno da API.
export interface Props {
	quizObject?: Quiz;
	moduleId: number;
	onAnswer: (answer: QuizAnswerResponse) => void;
}

const QuizContainerWrapper: React.FC<Props> = ({ quizObject, moduleId, onAnswer, ...props }: Props) => {
	const [answers, setAnswers] = useState<QuizAnswer[]>([]);
	const [error, setError] = useState("");
	const [errorIds, setErrorIds] = useState<boolean[]>([]);
	const [showCorrection, setShowCorrection] = useState(false);
	const [bestAnswer, setBestAnswer] = useState<Attempt | null>(null);
	const [disableButton, setDisableButton] = useState(false);
	const [isApproved, setIsApproved] = useState(false);
	const [allAttempts, setAllAttempts] = useState<Attempt[] | null>([]);
	const [showCorrectionVideo, setShowCorrectionVideo] = useState(false);

	const populateAnswers = (questionId: number, answerId: number, index: number) => {
		const ansObj: QuizAnswer = { questao: questionId, resposta: answerId };

		const filteredAnswers = answers.filter((answer) => answer.questao !== ansObj.questao);

		setDisableButton(false);
		setAnswers([...filteredAnswers, ansObj]);
	};

	const sendAnswers = async () => {
		setDisableButton(true);
		setErrorIds([]);
		setError("");
		if (!quizObject) return;

		const questionIds = quizObject.questoes.map((questao: QuizQuestion) => questao.id);
		const answeredQuestions = answers.map((answer: QuizAnswer) => answer.questao);
		const notAnsweredQuestions = questionIds.map((questionId) => !answeredQuestions.includes(questionId));

		if (answers.length !== quizObject?.questoes.length) {
			setError("Por favor, responda a todas as perguntas.");
			setErrorIds(notAnsweredQuestions);
			setDisableButton(false);
			return;
		}

		// limpar a seleção de tds os radio buttons
		Array.from(document.querySelectorAll('input[type="radio"]:checked'), (input) => ((input as HTMLInputElement).checked = false));

		const courseService = new CourseService();
		const response = await courseService.answer_quiz(answers, moduleId);

		//console.log("RSPONS Answer TSX => ", response);

		alert("Sua resposta foi computada com sucesso. O resultado da sua tentativa está na parte superior desta página.");

		//Manda a resposta pro evento onAnswer para que o componente pai possa renderizar
		onAnswer(response);

		if (response.tentativa >= quizObject.chances) {
			await getAllAttempts();

			const notaCorte = parseInt(quizObject.nota_corte);
			// checar cada nota pra saber se é maior q a nota de corte e retornar apenas se for true

			const notasAluno = quizObject.avaliacoes_respondidas.map((av: any) => parseInt(av.nota) >= notaCorte).filter(Boolean);

			if (notasAluno.length > 0) {
				setIsApproved(true);
			}

			setShowCorrection(true);
		}
		setAnswers([]);
		setDisableButton(false);

		window.scrollTo(0, 0);
	};

	const getAllAttempts = async () => {
		const courseService = new CourseService();
		const response = await courseService.get_quiz_by_module_id(moduleId);

		const avsResp = response.avaliacoes_respondidas.map((av: any) => {
			return {
				...av,
				respostas: av.respostas.sort((a: any, b: any) => {
					if (a.questao > b.questao) {
						return 1;
					}
					if (a.questao < b.questao) {
						return -1;
					}
					return 0;
				}),
			};
		});

		setBestAnswer(avsResp[0]);
		return setAllAttempts(avsResp);
	};

	const changeAttemptToShow = (answers: any) => {
		//console.log("SET RESPOSTAS => ", answers);
		setBestAnswer(answers);
	};

	useEffect(() => {
		if (!quizObject) return;
		getAllAttempts();
		if (quizObject.chances_esgotadas) {
			const notaCorte = parseInt(quizObject.nota_corte);
			// checar cada nota pra saber se é maior q a nota de corte e retornar apenas se for true
			const notasAluno = quizObject.avaliacoes_respondidas.map((av: any) => parseInt(av.nota) > notaCorte).filter(Boolean);

			if (notasAluno.length > 0) {
				setIsApproved(true);
			}

			setShowCorrection(true);
		}
	}, [quizObject]);

	return (
		<QuizContainerContainer {...props}>
			{/* Aprovado? {isApproved ? "sim" : "não"} */}
			{quizObject ? (
				<>
					{showCorrection ? (
						<>
							<p>Você já esgotou as chances de responder a este questionário.</p>
							{isApproved && bestAnswer && bestAnswer.video && (
								<>
									<button className={"btnTentativa"} style={{ cursor: "pointer", marginBottom: "24px" }} onClick={() => setShowCorrectionVideo(!showCorrectionVideo)}>
										{showCorrectionVideo ? "Ocultar" : "Exibir"} vídeo da correção do questionário
									</button>
									{showCorrectionVideo && (
										<VideoWrapper>
											<ReactPlayer controls width='100%' height='100%' url={bestAnswer.video} />
										</VideoWrapper>
									)}
								</>
							)}
							{allAttempts && allAttempts.length > 0 && (
								<>
									<Box display='flex' justifyContent='flex-start' margin='0 0 24px'>
										{allAttempts.map((attempt: Attempt) => (
											<button
												key={attempt.id}
												className={bestAnswer && bestAnswer.id === attempt.id ? "selected btnTentativa" : "btnTentativa"}
												style={{ cursor: "pointer" }}
												onClick={() => changeAttemptToShow(attempt)}>
												Tentativa {attempt.tentativa}
											</button>
										))}
									</Box>
								</>
							)}

							{bestAnswer && (
								<>
									{quizObject.questoes.map((questao: QuizQuestion, index) => (
										<QuestionContainer isError={false}>
											<CorrectionTitle>
												<div className='correctionIcon'>
													{bestAnswer.respostas[index].correta ? (
														<>
															<IoMdCheckmark size={24} color='#4BB543' />
														</>
													) : (
														<>
															<IoMdClose size={24} color='#D9200A' />
														</>
													)}
												</div>
												<QuestionTitle
													dangerouslySetInnerHTML={{
														__html: `<b class="qnum">${index + 1} - </b><div class="cenunciado">${questao.enunciado.split("<p>&nbsp;</p>").join("")}</div>`,
													}}
												/>
											</CorrectionTitle>
											<QuestionOptionsContainer>
												{questao.alternativas.map((alternativa: QuizAlternative) => (
													<>
														<RadioWrapper>
															<div className='correctionIcon'>
																{bestAnswer.respostas[index].questao === questao.id && bestAnswer.respostas[index].resposta === alternativa.id ? (
																	<>
																		{bestAnswer.respostas[index].questao === questao.id && bestAnswer.respostas[index].correta ? (
																			<>
																				<IoMdCheckmark size={24} color='#4BB543' />
																			</>
																		) : (
																			<>
																				<IoMdClose size={24} color='#D9200A' />
																			</>
																		)}
																	</>
																) : (
																	<>{alternativa.correta && isApproved && <IoMdCheckmark size={24} color='#4BB543' />}</>
																)}
															</div>
															<div className='correctionText'>{alternativa.alternativa}</div>
														</RadioWrapper>
													</>
												))}
											</QuestionOptionsContainer>
											{bestAnswer.respostas[index].comentario && isApproved && (
												<QuestionCommentary>
													<big>Comentário da questão:</big>
													<div dangerouslySetInnerHTML={{ __html: bestAnswer.respostas[index].comentario }}></div>
												</QuestionCommentary>
											)}
										</QuestionContainer>
									))}
								</>
							)}
						</>
					) : (
						<>
							{quizObject.questoes.map((questao: QuizQuestion, index) => (
								<QuestionContainer isError={errorIds[index]}>
									<QuestionTitle dangerouslySetInnerHTML={{ __html: `<b class="qnum">${index + 1} - </b><div class="enunciado">${questao.enunciado}</div>` }} />
									<QuestionOptionsContainer>
										{questao.alternativas.map((alternativa: QuizAlternative) => (
											<RadioWrapper>
												<input
													onChange={() => populateAnswers(questao.id, alternativa.id, index)}
													type='radio'
													name={`${index}-${questao.enunciado}`}
													id={alternativa.id.toString()}
													value={alternativa.id}
												/>
												<span>{alternativa.alternativa}</span>
											</RadioWrapper>
										))}
										{errorIds[index] && (
											<>
												<ErrorText>Responda a esta questão antes de enviar.</ErrorText>
											</>
										)}
									</QuestionOptionsContainer>
								</QuestionContainer>
							))}
							<ButtonWrapper>
								{error && <ErrorText>{error}</ErrorText>}
								<Button onClick={sendAnswers} variant='contained' color='primary' disabled={disableButton}>
									Confirmar Resposta
								</Button>
							</ButtonWrapper>
						</>
					)}
				</>
			) : (
				"Ocorreu um erro ao carregar o questionário. Tente novamente"
			)}
		</QuizContainerContainer>
	);
};

QuizContainerWrapper.defaultProps = {};

export { QuizContainerWrapper as QuizContainer };
