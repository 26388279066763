import React, { useEffect, useState } from "react";

import { languages, TLanguage, isLanguage } from '../../language';
import { ILanguageStringList } from "../../types/language";

interface Props {
	children: React.ReactNode;
}

type IUseLanguage = {
	pickLanguage: (l: TLanguage) => void,
	getLanguageStrings: () => ILanguageStringList
	currentLanguage: TLanguage
};

const LanguageContext = React.createContext<IUseLanguage>({
	pickLanguage: () => { }, //
	getLanguageStrings() { return {} as ILanguageStringList },
	currentLanguage: 'ptbr'
});

export const LanguageProvider = (props: Props) => {
	const { children } = props;
	const [language, setLanguage] = useState<TLanguage>('ptbr')

	const getLanguageStrings = (): ILanguageStringList => {
		return languages[language]
	}

	const pickLanguage = (lang: TLanguage) => {
		setLanguage(lang)
		localStorage.setItem('rd-med-lang', lang)
	}

	useEffect(() => {
		const lang = localStorage.getItem('rd-med-lang');

		if (lang && isLanguage(lang)) {
			pickLanguage(lang)
		}
	}, [])

	const value = {
		pickLanguage,
		getLanguageStrings,
		currentLanguage: language,
	};

	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
export const useLanguageContext = () => React.useContext(LanguageContext);
