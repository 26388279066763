import styled from "styled-components";
import media from "../../styles/media";

const ClassFileUploaderContainer = styled.div`
	.container {
		aside {
			margin-bottom: 30px;
		}
		&.dropzoneContainer {
			position: relative;
		}
		.uploading {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			background: rgba(255, 255, 255, 0.7);
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				margin: 0;
			}
		}
	}
	.dropzone {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		background: rgba(0, 0, 0, 0.015);
		color: rgba(0, 0, 0, 0.7);
		cursor: pointer;
		transition: 0.3s;
		border-radius: 10px;
		border: 1px dashed #ccc;
		padding: 35px;
		margin: 0 0 30px;
		&:hover {
			background: rgba(0, 0, 0, 0.03);
			box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
		}
		p {
			margin: 0 0 0 24px;
			padding: 0;
			line-height: 1.2em;
			${media.lessThan("medium")`
				text-align: center;
				width: 100%;
				margin: 24px 0 0;
			`}
		}
	}
`;

const FileItemsList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	margin-top: 16px;
`;
export { ClassFileUploaderContainer, FileItemsList };
