import React, { createContext, useContext, ReactNode } from "react";

import useAuth from "./hooks/useAuth";

interface UserContextState {
	authenticated: boolean;
	tenantData: any;
	userData: any;
	loading: boolean;
	handleLogin(email: string, password: string): Promise<object>;
	handleLogout(): void;
	isPlus(): boolean;
	alunoABA(): boolean;
	showPos(): boolean;
}

const UserContext = createContext({} as UserContextState);

interface ProviderProps {
	children: ReactNode;
}

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
	const { authenticated, tenantData, userData, loading, handleLogin, handleLogout } = useAuth();

	const isPlus = () => {
		const groups = userData.grupos.split(",");
		if (groups.includes("Alunos") || groups.includes("NMAAlunos") || groups.includes("Mentores") || groups.includes("Tutor")) {
			return true;
		}
		return false;
	};

	const alunoABA = () => {
		const groups = userData.grupos.split(",");
		if (groups.includes("CursoABA") || groups.includes("CursoABA2021") || groups.includes("CursoABA2022") || groups.includes("Mentores") || groups.includes("Tutor")) {
			return true;
		}
		return false;
	};

	const showPos = () => {
		const userGroups = userData.grupos.split(",");
		if (userGroups.includes("PosGraduacaoT1") || userGroups.includes("PosGraduacaoT2") || userGroups.includes("Tutor")) {
			return true;
		}
		return false;
	};

	return (
		<UserContext.Provider
			value={{
				userData,
				tenantData,
				loading,
				authenticated,
				handleLogin,
				handleLogout,
				isPlus,
				showPos,
				alunoABA,
			}}>
			{children}
		</UserContext.Provider>
	);
};

export const useUserContext = () => useContext(UserContext);

export { AuthProvider };
