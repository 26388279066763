import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { CourseDetailProvider } from "../../context/courseDetail/courseDetailContext";

import CourseDetailContent from "./CourseDetailContent";

interface CourseParams {
	slug: string;
	aulaid?: string;
}

const CourseDetail: React.FC = ({ ...props }) => {
	const { slug, aulaid } = useParams<CourseParams>();
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	return (
		<CourseDetailProvider slug={slug} aulaid={aulaid}>
			<CourseDetailContent />
		</CourseDetailProvider>
	);
};

export { CourseDetail };
