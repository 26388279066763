import { ILanguageStringList } from "../types/language";

export const enus: ILanguageStringList = {
	language: "English (US)",
	appName: "B42 Agency",
	appDescription: "The official platform of B42 Agency",
	subtitle: "The official platform of B42 Agency",
	featureSliderTitle: "Featured",
	categoryText: "Category",
	categoriesText: "Categories",
	tagsText: "Tags",
	recommendedArticles: "Recommended Articles",
	exclusiveSectionText: "This area is exclusively made for our subscribers, like you.",
	wrongCredentials: "Wrong login credentials",
	loadMore: {
		articles: "Load more articles",
		news: "Load more news",
		downloads: "Load more downloads",
		comments: "Load more comments",
	},
	meta: {
		in: "on",
		likeArticle: "Like article",
		likedArticle: "You liked this article",
		likeNews: "Like",
		likedNews: "You liked",
		source: "Source",
		credits: "Credits",
	},
	comments: {
		title: "Comments",
		new: "New Comment",
		description: "Description",
		createModalTitle: "New Comment",
		editModalTitle: "Edit Comment",
		saveBtn: "Save",
		cancelBtn: "Cancel",
	},
	titles: {
		articles: "Articles",
		singleArticle: "Reading Article",
		multimedia: "Videos",
		news: "News",
		home: "Home",
		courses: "Courses",
		downloads: "Downloads",
	},
	header: {
		logout: "Logout",
	},
	footer: {
		copyrightText: `${new Date().getFullYear()} - B42®`,
		privacyPolicy: {
			text: "Privacy policy",
			link: "",
		},
		terms: {
			text: "Terms of use",
			link: "",
		},
	},
	login: {
		user: "Username",
		password: "Password",
		forgot: "Forgot your password?",
		loginBtn: "Login",
		rightText: "",
	},
	passwordRecovery: {
		rightText: "Password recovery",
		inputPlaceholder: "Enter your email",
		backLink: "Back to login",
		sendButton: "Recover",
	},
	home: {
		mainCourses: "Main Courses",
		thisWeek: "Featured This Week",
		featuredNews: "Featured News",
		featuredArticle: "Featured Article",
		multimedia: "Videos",
		otherCourses: "Other Courses",
	},
	news: {
		latestNews: "Latest News",
		newResponse: "Comment this",
	},
	multimedia: {
		playlistContent: "Playlist content",
		released: "Released",
		episode: "Episode",
	},
	courses: {
		seeMoreClasses: "See all lessons",
		createdAt: "Created",
		card: {
			status: {
				notStarted: "Not started",
				finished: "Finished",
				inProgress: "In Progress",
			},
			availableClasses: "Available Lessons",
			hasCertificate: "Certificate Available",
		},
		course: {
			quizAlertMessage: "You must complete all previous quizes before accessing this lesson",
			quizAnswered: "Answered",
			unread: "Unread",
			newComments: "New Comments",
			courseContent: "Course content",
			released: "Released",
			lessonNum: "Lesson",
			optional: "Optional Lesson",
			lessonDoneLabel: "Finished",
			markAsDoneBtn: "Mark this lesson as finished",
			downloadsCard: "downloads in this lesson",
			downloadsLesson: "Downloads",
			sendfiles: "Click here or drag your files here to upload them. Accepted files: .pdf, .ppt, .pptx, .doc, .docx, .txt, .xls, .xlsx, .jpg, .png, .jpe",
			sendFiles2: "The number of files you can send is:",
			noModules: "No módules found",
			readMore: "Read More",
			readLess: "Collapse",
			comments: {
				tittle: "Comments",
				updateBtn: "Update Comments",
				loading: "Loading",
				loadMore: "Load more comments",
			},
			buttons: {
				nextLesson: "Next Lesson",
				prevLesson: "Previous Lesson",
				emitCertificate: "Claim your certificate",
				emitBadge: "Claim your badge",
				issuing: "Generating",
			},
			quiz: {
				description: "Quiz Description",
				prevResults: "Previous results:",
				answersText: "The correction of this quiz will be shown after you've used all your attempts of responding",
				chances: "Number of Attempts:",
				text: "Quiz",
				quizTry: "Attempt",
				grade: "Grade",
			},
		},
	},
	profile: {
		achievements: "Achievements",
		cancelBtn: "Cancel",
		saveBtn: "Save",
		addressMap: "Address and Map",
		editMap: "Edit",
		claimed: "Claimed",
		certificates: "My Certificates",
		badges: "My Badges",
		termsAccepted: "My accepted terms",
		buttons: {
			edit: "Edit profile",
			logout: "Logout",
			changePassword: "Change password",
		},
		changePasswordModal: {
			title: "Change Password",
			oldPassword: "Current password",
			newPassword: "New password",
			confirmPassword: "Confirm new password",
		},
		updateAvatarModal: {
			title: "Update Profile Picture",
			fileButton: "Pick a file",
		},
		editProfileModal: {
			title: "Edit Profile",
			name: "Name",
			email: "Email",
			occupation: "Occupation",
			personalText: "About You",
			phone: "Phone Number",
			address: {
				street: "Street",
				number: "Number",
				extraInfo: "Extra info",
				neighborhood: "Line 2",
				zip: "Zip Code",
				city: "City",
				state: "State/Province",
			},
			notifications: {
				title: "Notifications",
				articles: "New Articles",
				downloads: "New Downloads",
				multimedia: "New Multimedia",
				courses: "Course Updates",
				comments: "New Responses to your comments",
				news: "New News Posted",
			},
		},
		availabilityToggle: {
			title: "Availability",
			public: "Public",
			private: "Private",
		},
	},
};
