import React, { useEffect, useState } from "react";

import { AccessTimersContainer, TimerBox } from "./AccessTimersStyled";

import StudentProfileService from "../../services/student.profile.service";
import { Typography } from "../Typography";
import { groupToCourseName } from "../../helpers/groupToCourseName";

export interface Props {}

const AccessTimersWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [timers, setTimers] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	const getTimers = async () => {
		setLoading(true);

		const studentProfileService = new StudentProfileService();
		const response = await studentProfileService.getAccessTimers();

		setTimers(response);
		console.log("r", response);
		setLoading(false);
	};

	useEffect(() => {
		getTimers();
	}, []);

	return (
		<AccessTimersContainer {...props}>
			<div style={{ width: `100%` }}>{timers.length > 0 ? <Typography variant='title'>Tempos de Acesso</Typography> : ""}</div>
			{loading ? (
				"Carregando..."
			) : (
				<>
					{timers.map((timer: any) => {
						if (timer.grupos) {
							return (
								<TimerBox diasRestantes={timer.tempo_restante}>
									<div>
										<strong>Cursos: </strong>
										<span>{groupToCourseName(timer.grupos)}</span>
									</div>
									<div>
										<strong>Data de Início: </strong>
										<span>{timer.inicio.split(" ")[0]}</span>
									</div>
									<div>
										<strong>Data de Término: </strong>
										<span>{timer.data_termino.split(" ")[0]}</span>
									</div>
									<div>
										<strong>Dias Restantes : </strong>
										<span>{timer.tempo_restante}</span>
									</div>
									{timer.tempo_restante < 90 && (
										<div>
											<strong className='alerta'>Acesso encerrando em breve</strong>
										</div>
									)}
								</TimerBox>
							);
						} else {
							return (
								<TimerBox diasRestantes={99999999999}>
									<big>Assinatura CIA Autismo</big>

									<div>
										<strong>Data de Início: </strong>
										<span>{timer.created.split(" ")[0]}</span>
									</div>
									<div>
										<strong>Data de Término: </strong>
										<span>{timer.data_termino.split(" ")[0]}</span>
									</div>
									<div>
										<strong>Dias Restantes : </strong>
										<span>{timer.tempo_restante}</span>
									</div>
								</TimerBox>
							);
						}
					})}
				</>
			)}
		</AccessTimersContainer>
	);
};

AccessTimersWrapper.defaultProps = {};

export { AccessTimersWrapper as AccessTimers };
