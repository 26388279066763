import styled from "styled-components";
import { darken } from "polished";
import media from "../../styles/media";

const CoursesContainer = styled.div`
	//display: flex;
	position: relative;
	.clearFilter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 30px;
		span {
			text-decoration: none;
			white-space: nowrap;
			border-radius: 100px;
			font-weight: 400;
			height: 35px;
			padding: 0 20px;
			line-height: 30px;
			font-size: 0.75rem;
			color: #222b37;
			cursor: pointer;
			box-sizing: border-box;
			background-color: #f2f2f2;
			border: 1px solid #e0e0e0;
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			margin: 5px 5px 0 5px;

			&:focus {
				border: 1px solid #e0e0e0;
			}
			&:hover {
				background-color: ${darken(0.2, "#E0E0E0")};
			}
		}
	}

	a,
	.courseLink {
		text-decoration: none;
		position: relative;
		transition: 0.3s ease;
		top: 0;
		h1 {
			transition: 0.3s ease;
		}
		&:hover {
			top: -10px;
			h1 {
				color: ${(props) => props.theme.color.primary};
			}
		}
	}
	.resultDiv {
		margin: 0 0 50px;
	}
	.coursesCounter {
		color: ${(props) => props.theme.color.secondary};
		padding-left: 8px;
	}
`;

const TrailsContainer = styled.div`
	display: flex;
	position: relative;
	.trailLink {
		position: relative;
		transition: 0.3s ease;
		top: 0;
		.trailName {
			transition: 0.3s ease;
		}
		&:hover {
			top: -20px;
			.trailName {
				color: ${(props) => props.theme.color.primary};
			}
		}
	}
`;
const TabSelectorContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0 30px;
`;
const TabSelector = styled.div`
	text-transform: uppercase;
	font-weight: 700;
	color: ${(props) => props.theme.color.primary};
	width: 200px;
	padding: 10px 0;
	text-align: center;
	background: #fff;
	transition: 0.3s;
	border: 1px solid rgba(0, 0, 0, 0.15);
	cursor: pointer;
	&:nth-child(1) {
		border-radius: 100px 0 0 100px;
	}
	&:nth-child(2) {
		border-radius: 0 100px 100px 0;
	}
	&.active,
	&:hover {
		background: ${(props) => props.theme.color.primary};
		color: #fff;
	}
	&.active {
		cursor: default;
	}
`;

const BannerDestaque = styled.div`
	width: 100%;
	margin-bottom: 15px;
	a,
	img {
		display: block;
	}
	img {
		width: 100%;
	}
	img.desk {
		display: block;
		${media.lessThan("medium")`
		display: none;
	`}
	}
	img.mob {
		display: none;
		${media.lessThan("medium")`
		display: block;
	`}
	}
`;

export {
	CoursesContainer, //
	TrailsContainer,
	TabSelectorContainer,
	TabSelector,
	BannerDestaque,
};
